import env from './env'
import network from './network.config'

const configs = {
  NETWORK: network[env],
  ADDRESS_SHOP: process.env.REACT_APP_ADDRESS_SHOP,
  ADDRESS_GENESIS: process.env.REACT_APP_ADDRESS_GENESIS,
  ADDRESS_GENERAL: process.env.REACT_APP_ADDRESS_GENERAL,
  ADDRESS_MONSTER: process.env.REACT_APP_ADDRESS_MONSTER,
  ADDRESS_FARM: process.env.REACT_APP_ADDRESS_FARM,
  ADDRESS_MARKETPLACE: process.env.REACT_APP_ADDRESS_MARKETPLACE,
  ADDRESS_TRAINING_ITEM: process.env.REACT_APP_ADDRESS_TRAINING_ITEM,
  ADDRESS_REGENERATION_ITEM: process.env.REACT_APP_ADDRESS_REGENERATION_ITEM,
  ADDRESS_FUSION_ITEM: process.env.REACT_APP_ADDRESS_FUSION_ITEM,
  ADDRESS_ENHANCE_ITEM: process.env.REACT_APP_ADDRESS_ENHANCE_ITEM,
  ADDRESS_POOL: process.env.REACT_APP_ADDRESS_POOL,
}

/**
 * Module exports
 */
export default configs;