import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api/blockchainApi'
import {
  getBalacePocSuccess,
  getBalacePocFailed,
  GET_BALANCEPOC_START,
} from '~/views/app/redux/actions/user'

/**
 * get balancePoc api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */

const getBalancePocApi = (props) => {
  var uri = `/api/sbt-nft/balancePoC`
  return api.get(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */

function* doGetBalancePoc(action) {
  try {
    const response = yield call(getBalancePocApi, action?.payload)
    yield put(getBalacePocSuccess(response))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response?.data)
    }
  } catch (error) {
    yield put(getBalacePocFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}
/**
 * [Watcher]
 * Watch get balancePoc
 */
export default function* watchGetBalancePoc() {
  yield takeLatest(GET_BALANCEPOC_START, doGetBalancePoc)
}
