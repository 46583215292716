import { call, put, takeLatest } from 'redux-saga/effects'
import {
  deleteSectorMemberSuccess,
  deleteSectorMemberFailed,
  DELETE_SECTOR_MEMBER_START,
} from '../../actions/sector'
import { api } from '~/services/api/gameApi'

/**
 * delete member sector pools api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const deleteMemberSectorsApi = (props) => {
  var uri = `/api/game/sector/delete-member`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doDeleteMemberSectors(action) {
  try {
    const response = yield call(deleteMemberSectorsApi, action?.payload)
    yield put(deleteSectorMemberSuccess(response))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(deleteSectorMemberFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch delete member sector pools
 */
export default function* watchDeleteMemberSector() {
  yield takeLatest(DELETE_SECTOR_MEMBER_START, doDeleteMemberSectors)
}
