import { addKeyToList } from '~/helpers/transform-data'
import {
  GET_LIST_BLACKLIST_START,
  GET_LIST_BLACKLIST_SUCCESS,
  GET_LIST_BLACKLIST_FAILED,
  //
  GET_LIST_ADD_BLACKLIST_START,
  GET_LIST_ADD_BLACKLIST_SUCCESS,
  GET_LIST_ADD_BLACKLIST_FAILED,
  //
  CREATE_BLACKLIST_START,
  CREATE_BLACKLIST_SUCCESS,
  CREATE_BLACKLIST_FAILED,
  //
  DELETE_BLACKLIST_START,
  DELETE_BLACKLIST_SUCCESS,
  DELETE_BLACKLIST_FAILED,
} from '../actions/blackList'

const initialState = {
  isLoadingGetListBlackList: false,
  isLoadingGetListAddBlackList: false,
  isLoadingCreate: false,
  isLoadingDelete: false,
  listBlackList: [],
}

/**
 * Black list reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function blackListsManagement(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_BLACKLIST_START:
      return {
        ...state,
        isLoadingGetListBlackList: true,
      }
    case GET_LIST_ADD_BLACKLIST_START:
      return {
        ...state,
        isLoadingGetListAddBlackList: true,
      }
    case CREATE_BLACKLIST_START:
      return {
        ...state,
        isLoadingCreate: true,
      }
    case DELETE_BLACKLIST_START:
      return {
        ...state,
        isLoadingDelete: true,
      }
    //
    case GET_LIST_BLACKLIST_SUCCESS:
      return {
        ...state,
        // listBlackList: action?.payload,
        listBlackList: addKeyToList(
          action?.payload?.data,
          action.payload?.currentPage,
          action.payload?.pageSize,
        ),
        total: action?.payload?.total,
        isLoadingGetListBlackList: false,
      }
    case GET_LIST_ADD_BLACKLIST_SUCCESS:
      return {
        ...state,
        listAddBlackList: action?.payload?.items,
        isLoadingGetListAddBlackList: false,
      }
    case CREATE_BLACKLIST_SUCCESS:
      return {
        ...state,
        isLoadingCreate: false,
      }
    case DELETE_BLACKLIST_SUCCESS:
      return {
        ...state,
        isLoadingDelete: false,
      }
    //
    case GET_LIST_BLACKLIST_FAILED:
      return {
        ...state,
        isLoadingGetListBlackList: true,
      }
    case GET_LIST_ADD_BLACKLIST_FAILED:
      return {
        ...state,
        isLoadingGetListAddBlackList: true,
      }
    case CREATE_BLACKLIST_FAILED:
      return {
        ...state,
        isLoadingCreate: false,
      }
    case DELETE_BLACKLIST_FAILED:
      return {
        ...state,
        isLoadingDelete: false,
      }
    default:
      return state
  }
}
