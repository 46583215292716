import React, { useEffect, useState, useRef } from 'react'
import { InMemoryDatabase } from 'brackets-memory-db'
import { BracketsManager } from 'brackets-manager'

import 'brackets-viewer/dist/brackets-viewer.min.css'
import 'brackets-viewer/dist/brackets-viewer.min.js'
import './styles.css'
import { Col, Row, Spin } from 'antd'
import { useUser } from '~/views/app/redux/hooks/useUser'

const TournamentBracketsEditor = ({
  type,
  participantsMatch_list,
  sponsor,
}) => {
  const {
    actions,
    data: { listUserByWallet },
  } = useUser()
  const [userWallet, setUserWallet] = useState([])
  const managerRef = useRef(null) // Using useRef for manager instance

  useEffect(() => {
    const userResult = sponsor?.final_standings
      ? sponsor.final_standings.map((item) => item.name)
      : []
    setUserWallet(userResult)
  }, [sponsor])

  useEffect(() => {
    if (sponsor && userWallet && userWallet.length > 0) {
      const params = {
        allWallets: userWallet,
        pageSize: 100,
        page: 1,
      }
      actions.getUserByWallet(params)
    }
  }, [sponsor, userWallet])

  const uniquePlayers = new Set()
  sponsor?.match_list?.forEach((match) => {
    uniquePlayers.add(match.player_1)
    uniquePlayers.add(match.player_2)
  })

  const uniquePlayersArray = Array.from(uniquePlayers)
  const playerPositionMap = {}
  sponsor?.match_list?.forEach((match) => {
    playerPositionMap[match.player_1] = match.player_1_id
    playerPositionMap[match.player_2] = match.player_2_id
  })

  const participants = uniquePlayersArray
    .map((player) => [player, playerPositionMap[player]])
    .sort((a, b) => a[1] - b[1])
    .map((pair) => pair[0])

  const [dataSponsor, setDataSponsor] = useState(null)
  const [finalRoundPosition, setFinalRoundPosition] = useState({})
  const [roundPosition, setRoundPosition] = useState({})
  const [viewPosition, setViewPosition] = useState({})
  const size = participants.length

  const nameParticipants = (data) => {
    if (listUserByWallet && listUserByWallet.length > 0) {
      return data?.map((item) => {
        const user = listUserByWallet.find(
          (user) => user.address_wallet === item,
        )
        return user ? user.user_name : item
      })
    }
    return data
  }

  const walletAddresses = dataSponsor?.participant?.map(
    (participant) => participant.name,
  )

  const updatedNames = nameParticipants(walletAddresses)

  const updatedParticipants = dataSponsor?.participant?.map(
    (participant, index) => ({
      ...participant,
      name: updatedNames[index],
    }),
  )

  const rendering = async () => {
    const storage = new InMemoryDatabase()
    managerRef.current = new BracketsManager(storage)

    await managerRef.current.create({
      name: 'Tournament Brackets',
      tournamentId: 0,
      type: type || 'single_elimination',
      seeding: participants,
      settings: {
        seedOrdering: ['inner_outer'],
        balanceByes: false,
        size: size,
        matchesChildCount: 0,
        consolationFinal: false,
      },
    })

    const tournamentData = await managerRef.current.get.stageData(0)
    setDataSponsor(tournamentData)
  }

  const updateMatchResults = async () => {
    if (!dataSponsor || !managerRef.current) return

    if (dataSponsor?.match?.length) {
      for (const match of dataSponsor.match) {
        const matchData = participantsMatch_list.find(
          (m) => m.match_id === match.id,
        )

        if (matchData) {
          const opponent1Result =
            matchData.winner === matchData.player_1_id ? 'win' : 'loss'
          const opponent2Result =
            matchData.winner === matchData.player_2_id ? 'win' : 'loss'

          try {
            await managerRef.current.update.match({
              id: match.id,
              opponent1: { id: match.opponent1.id, result: opponent1Result },
              opponent2: { id: match.opponent2.id, result: opponent2Result },
            })
          } catch (error) {
            console.error('Error updating match:', match.id, error)
          }
        }
      }

      const updatedData = await managerRef.current.get.stageData(0)
      if (JSON.stringify(updatedData) !== JSON.stringify(dataSponsor)) {
        setDataSponsor(updatedData)
      }
    }
  }

  useEffect(() => {
    if (size === 16) {
      setFinalRoundPosition({
        bottom: '56%',
        right: '7%',
      })
      setRoundPosition({
        top: '-6%',
        left: '0',
      })
      setViewPosition({})
    } else if (size === 8) {
      setFinalRoundPosition({
        bottom: '15rem',
        right: '8rem',
      })
      setRoundPosition({
        top: '-7px',
        left: '0',
      })
      setViewPosition({
        margin: '6rem',
      })
    } else if (size === 4) {
      setFinalRoundPosition({
        bottom: '13rem',
        right: '11rem',
      })
      setRoundPosition({
        bottom: '26rem',
        left: '0',
      })
      setViewPosition({
        margin: '8rem',
      })
    } else if (size === 2) {
      setFinalRoundPosition({
        bottom: '18rem',
        right: '17rem',
      })
      setRoundPosition({
        bottom: '30rem',
        left: '0',
      })
      setViewPosition({
        margin: '14rem',
      })
    }
  }, [size])

  useEffect(() => {
    rendering()
  }, [size])

  useEffect(() => {
    if (dataSponsor) {
      updateMatchResults()
    }
  }, [participantsMatch_list, dataSponsor])

  useEffect(() => {
    if (dataSponsor && window.bracketsViewer) {
      const updatedDataSponsor = {
        ...dataSponsor,
        participant: updatedParticipants,
      }
      const bracketsViewerNode = document.querySelector('.brackets-viewer')
      if (bracketsViewerNode) {
        bracketsViewerNode.innerHTML = ''
        window.bracketsViewer.render({
          stages: updatedDataSponsor.stage,
          matches: updatedDataSponsor.match,
          matchGames: updatedDataSponsor.match_game,
          participants: updatedDataSponsor.participant,
        })
      } else {
        console.error('.brackets-viewer element not found')
      }
    } else {
      console.error('dataSponsor or window.bracketsViewer is not available')
    }
  }, [dataSponsor, updatedParticipants])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }, [])
  return (
    <Spin spinning={loading}>
      <Row style={{ position: 'relative' }}>
        <Col
          span={24}
          className="text-[20px] absolute "
          style={{ ...roundPosition }}
        >
          Round: {dataSponsor?.round?.length}
        </Col>
        <Col span={24} style={{ ...viewPosition }}>
          <div className="brackets-viewer"></div>
        </Col>
        <img
          className="w-[50px] h-[50px] absolute"
          src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
          alt="Tournament Logo"
          style={{
            ...finalRoundPosition,
          }}
        />
      </Row>
    </Spin>
  )
}

export default TournamentBracketsEditor
