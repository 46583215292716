import React, { useEffect, useState } from 'react'
import { Row, Col, Input, Empty, Spin } from 'antd'
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import { formatNumber } from '~/helpers/common'

const PageSortRankingGuild = ({
  sortRankingGuild,
  handleSearchGuild,
  keywordGuild,
  isLoading,
  TotalRankingGuild,
  TotalRankedScore,
  TotalExplorationScore,
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10
  const totalPages = Math.ceil(sortRankingGuild.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const currentItems = sortRankingGuild.slice(startIndex, endIndex)
  // Handle page change
  const goToPreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  const goToNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }
  useEffect(() => {
    if (totalPages === 0) {
      setCurrentPage(0)
    } else {
      setCurrentPage(1)
    }
  }, [totalPages])

  return (
    <div style={{ fontFamily: 'Rexliafree' }}>
      <Spin spinning={isLoading}>
        <div
          className="guildInfo"
          style={{
            maxHeight: '515px',
            overflowY: 'auto',
            padding: '0 20px',
          }}
        >
          {currentItems.length > 0 ? (
            currentItems.map((item, index) => (
              <div
                className="mb-2"
                style={{
                  border: '2px solid rgba(12, 200, 254, 1)',
                  padding: '10px',
                  background: 'rgb(13, 39, 88)',
                  borderRadius: '20px',
                  position: 'relative',
                }}
              >
                {index + 1 > 0 && index + 1 < 7 && (
                  <img
                    className="absolute left-[-20px] top-[-3px] w-[45px] h-[45px]"
                    src={`${process.env.PUBLIC_URL}/svgs/guild/level/${
                      index + 1
                    }.svg`}
                    alt=""
                    style={{ zIndex: 1 }}
                  />
                )}
                <Row>
                  <Col span={16}>
                    <Row>
                      <Col
                        span={5}
                        className="flex justify-center items-center"
                      >
                        <img
                          className={`h-[50px]  ${
                            item?.guild_image?.startsWith(
                              process.env.REACT_APP_BASE_URL_IMAGE,
                            )
                              ? ''
                              : 'mt-[10px]'
                          }`}
                          src={
                            item?.guild_image?.startsWith(
                              process.env.REACT_APP_BASE_URL_IMAGE,
                            )
                              ? item.guild_image
                              : `${process.env.PUBLIC_URL}/svgs/guild/ImageGuild/${item?.guild_image}.svg`
                          }
                          alt=""
                        />
                      </Col>
                      <Col span={14} className="flex items-center ">
                        <div className="text-[20px] mt-[10px]">
                          {item?.guild_name}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row>
                      <Col span={8} className="flex items-center">
                        <img
                          className="w-[20px] h-[20px] mr-2 mt-[2px]"
                          src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                          alt=""
                        />
                      </Col>
                      <Col span={16}>
                        {formatNumber(
                          TotalRankingGuild(
                            TotalRankedScore(item?.guild_score),
                            TotalExplorationScore(item?.guild_score),
                          ),
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8} className="flex items-center">
                        <img
                          className="w-[15px] h-[15px] ml-[2px]  mt-[2px]"
                          src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                          alt=""
                        />
                      </Col>
                      <Col span={16}>
                        {formatNumber(TotalRankedScore(item?.guild_score))}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8} className="flex items-center">
                        <img
                          className="w-[15px] h-[15px] ml-[2px]"
                          src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                          alt=""
                        />
                      </Col>
                      <Col span={16}>
                        {formatNumber(TotalExplorationScore(item?.guild_score))}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </Spin>

      <Row className="mt-4">
        <Col span={12} className="flex justify-center items-center">
          <CaretLeftOutlined
            style={{
              fontSize: '25px',
              cursor: currentPage > 1 ? 'pointer' : 'not-allowed',
              color: currentPage > 1 ? 'inherit' : 'black',
            }}
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
          />
          <div style={{ margin: '0 15%', fontSize: '18px' }}>
            {currentPage} / {totalPages}
          </div>
          <CaretRightOutlined
            style={{
              fontSize: '25px',
              cursor: currentPage < totalPages ? 'pointer' : 'not-allowed',
              color: currentPage < totalPages ? 'inherit' : 'black',
            }}
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
          />
        </Col>
        <Col span={12} className="flex justify-center items-center">
          <img
            className="w-[25px] h-[25px] mr-2 mt-[2px]"
            src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
            alt=""
          />
          <Input
            style={{ width: '133px', height: '28px' }}
            onChange={handleSearchGuild}
            placeholder="Enter text..."
            value={keywordGuild}
          />
          <img
            className="w-[30px] h-[30px] ml-2 cursor-pointer"
            src={`${process.env.PUBLIC_URL}/svgs/guild/searchSubmit.svg`}
            alt=""
          />
        </Col>
      </Row>
    </div>
  )
}

export default PageSortRankingGuild
