import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api'
import {
  getUserByWalletSuccess,
  getUserByWalletFailed,
  GET_USER_BY_WALLET_START,
} from '~/views/app/redux/actions/user'

/**
 * get user by wallet api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
// const getUserByWalletApi = (params) => {
//   const uri = `/admin/manager-tes-users/user-by-wallet/${params}`
//   return api.get(uri)
// }

const getUserByWalletApi = (props) => {
  var uri = `/admin/manager-tes-users/user-by-wallets`
  return api.get(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */

function* doGetUserByWallet(action) {
  try {
    const response = yield call(getUserByWalletApi, action?.payload)
    yield put(getUserByWalletSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response?.data)
    }
  } catch (error) {
    yield put(getUserByWalletFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}
/**
 * [Watcher]
 * Watch get user by wallet
 */
export default function* watchGetUserByWallet() {
  yield takeLatest(GET_USER_BY_WALLET_START, doGetUserByWallet)
}
