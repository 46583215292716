import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'

const RankingGuild = ({ dataCycleGuildRank }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  useEffect(() => {
    const now = Date.now() / 1000

    const index = dataCycleGuildRank.findIndex((cycle) => {
      const fromDate = Number(cycle.from_date_cycle)
      const toDate = Number(cycle.to_date_cycle)
      return now >= fromDate && now <= toDate
    })

    if (index !== -1) {
      setCurrentIndex(index)
    }
  }, [dataCycleGuildRank])
  const goToPrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  const goToNext = () => {
    if (currentIndex < dataCycleGuildRank.length - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const currentData = dataCycleGuildRank[currentIndex]

  const totalScore = (ranked_score, exploration_score) => {
    return Number(ranked_score) + Number(exploration_score)
  }

  function formatTimestampToMonthYear(timestamp) {
    const date = new Date(timestamp * 1000)
    const options = { month: 'short', year: 'numeric' }
    return date.toLocaleDateString('en-US', options).replace(' ', '. ')
  }

  function getNextTwoMonthsWithCurrentCheck(timestamp) {
    const startDate = new Date(timestamp * 1000)
    const months = []

    for (let i = 0; i < 3; i++) {
      const formattedDate = formatTimestampToMonthYear(
        startDate.getTime() / 1000,
      )
      const isCurrentMonth = isMonthCurrent(startDate)
      months.push({ month: formattedDate, isCurrentMonth })
      startDate.setMonth(startDate.getMonth() + 1)
    }

    return months
  }

  function isMonthCurrent(date) {
    const now = new Date()
    return (
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear()
    )
  }

  const nextTwoMonths = getNextTwoMonthsWithCurrentCheck(
    currentData.from_date_cycle,
  )

  return (
    <>
      {currentData?.dataMonthCycle?.length > 0 && (
        <Row
          className="mt-[15px] p-[12px]"
          justify="space-between"
          style={{
            fontFamily: 'Rexliafree',
            display: 'flex',
            justifyContent: 'center',
            gap: '6%',
          }}
        >
          <Col
            span={7}
            style={{
              border: '1px solid #E5E5E5',
              borderRadius: 8,
              background:
                nextTwoMonths[0].isCurrentMonth === true
                  ? 'rgb(12, 200, 254)'
                  : '#004558',
              padding: '0',
            }}
          >
            <div>
              <Row style={{ padding: '0 12px' }}>
                <Col span={12} className="text-[20px]">
                  {nextTwoMonths[0].month}
                </Col>
                <Col
                  span={12}
                  className="flex items-center text-[20px] justify-end"
                >
                  <img
                    className="w-[35px] h-[35px]"
                    src={`${process.env.PUBLIC_URL}/svgs/guild/oas.svg`}
                    alt=""
                  />
                  <div>{currentData?.dataMonthCycle?.[0]?.oas_earned ?? 0}</div>
                </Col>
              </Row>
              <div
                style={{
                  background: '#0D2758',
                  padding: '12px',
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
                }}
              >
                <Row>
                  <Col span={13} className="text-[16px]">
                    Rank
                  </Col>
                  <Col span={11} className="text-[16px] flex justify-end">
                    {currentData?.dataMonthCycle?.[0]?.guild_placement &&
                    currentData?.dataMonthCycle?.[0]?.total_placement
                      ? `${currentData.dataMonthCycle[0].guild_placement}/${currentData.dataMonthCycle[0].total_placement}`
                      : '-/-'}
                  </Col>
                </Row>
                <Row>
                  <Col span={13} className="text-[16px]">
                    Total Score
                  </Col>
                  <Col span={11} className="text-[16px] flex justify-end">
                    {totalScore(
                      currentData?.dataMonthCycle?.[0]?.ranked_score ?? 0,
                      currentData?.dataMonthCycle?.[0]?.exploration_score ?? 0,
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={13} className="flex items-center">
                    <img
                      className="w-[12px] h-[12px] mr-1"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                      alt=""
                    />
                    <div className="text-[12px]">Ranked Match</div>
                  </Col>
                  <Col
                    span={11}
                    className="flex items-center justify-end text-[12px]"
                  >
                    {currentData?.dataMonthCycle?.[0]?.ranked_score ?? 0}
                  </Col>
                </Row>
                <Row>
                  <Col span={13} className="flex items-center">
                    <img
                      className="w-[12px] h-[12px] mr-1"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                      alt=""
                    />
                    <div className="text-[12px]">Exploration</div>
                  </Col>
                  <Col
                    span={11}
                    className="flex items-center justify-end text-[12px]"
                  >
                    {currentData?.dataMonthCycle?.[0]?.exploration_score ?? 0}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col
            span={7}
            style={{
              border: '1px solid #E5E5E5',
              borderRadius: 8,
              background:
                nextTwoMonths[1].isCurrentMonth === true
                  ? 'rgb(12, 200, 254)'
                  : '#004558',
              padding: '0',
            }}
          >
            <div>
              <Row style={{ padding: '0 12px' }}>
                <Col span={12} className="text-[20px]">
                  {nextTwoMonths[1].month}
                </Col>
                <Col
                  span={12}
                  className="flex items-center text-[20px] justify-end"
                >
                  <img
                    className="w-[35px] h-[35px]"
                    src={`${process.env.PUBLIC_URL}/svgs/guild/oas.svg`}
                    alt=""
                  />
                  <div>{currentData?.dataMonthCycle?.[1]?.oas_earned ?? 0}</div>
                </Col>
              </Row>
              <div
                style={{
                  background: '#0D2758',
                  padding: '12px',
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
                }}
              >
                <Row>
                  <Col span={13} className="text-[16px]">
                    Rank
                  </Col>
                  <Col span={11} className="text-[16px] flex justify-end">
                    {currentData?.dataMonthCycle?.[1]?.guild_placement &&
                    currentData?.dataMonthCycle?.[1]?.total_placement
                      ? `${currentData.dataMonthCycle[1].guild_placement}/${currentData.dataMonthCycle[1].total_placement}`
                      : '-/-'}
                  </Col>
                </Row>
                <Row>
                  <Col span={13} className="text-[16px]">
                    Total Score
                  </Col>
                  <Col span={11} className="text-[16px] flex justify-end">
                    {totalScore(
                      currentData?.dataMonthCycle?.[1]?.ranked_score ?? 0,
                      currentData?.dataMonthCycle?.[1]?.exploration_score ?? 0,
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={13} className="flex items-center">
                    <img
                      className="w-[12px] h-[12px] mr-1"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                      alt=""
                    />
                    <div className="text-[12px]">Ranked Match</div>
                  </Col>
                  <Col
                    span={11}
                    className="flex items-center justify-end text-[12px]"
                  >
                    {currentData?.dataMonthCycle?.[1]?.ranked_score ?? 0}
                  </Col>
                </Row>
                <Row>
                  <Col span={13} className="flex items-center">
                    <img
                      className="w-[12px] h-[12px] mr-1"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                      alt=""
                    />
                    <div className="text-[12px]">Exploration</div>
                  </Col>
                  <Col
                    span={11}
                    className="flex items-center justify-end text-[12px]"
                  >
                    {currentData?.dataMonthCycle?.[1]?.exploration_score ?? 0}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col
            span={7}
            style={{
              border: '1px solid #E5E5E5',
              borderRadius: 8,
              background:
                nextTwoMonths[2].isCurrentMonth === true
                  ? 'rgb(12, 200, 254)'
                  : '#004558',
              padding: '0',
            }}
          >
            <div>
              <Row style={{ padding: '0 12px' }}>
                <Col span={12} className="text-[20px]">
                  {nextTwoMonths[2].month}
                </Col>
                <Col
                  span={12}
                  className="flex items-center text-[20px] justify-end"
                >
                  <img
                    className="w-[35px] h-[35px]"
                    src={`${process.env.PUBLIC_URL}/svgs/guild/oas.svg`}
                    alt=""
                  />
                  <div>{currentData?.dataMonthCycle?.[2]?.oas_earned ?? 0}</div>
                </Col>
              </Row>
              <div
                style={{
                  background: '#0D2758',
                  padding: '12px',
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
                }}
              >
                <Row>
                  <Col span={13} className="text-[16px]">
                    Rank
                  </Col>
                  <Col span={11} className="text-[16px] flex justify-end">
                    {currentData?.dataMonthCycle?.[2]?.guild_placement &&
                    currentData?.dataMonthCycle?.[2]?.total_placement
                      ? `${currentData.dataMonthCycle[2].guild_placement}/${currentData.dataMonthCycle[2].total_placement}`
                      : '-/-'}
                  </Col>
                </Row>
                <Row>
                  <Col span={13} className="text-[16px]">
                    Total Score
                  </Col>
                  <Col span={11} className="text-[16px] flex justify-end">
                    {totalScore(
                      currentData?.dataMonthCycle?.[2]?.ranked_score ?? 0,
                      currentData?.dataMonthCycle?.[2]?.exploration_score ?? 0,
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={13} className="flex items-center">
                    <img
                      className="w-[12px] h-[12px] mr-1"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                      alt=""
                    />
                    <div className="text-[12px]">Ranked Match</div>
                  </Col>
                  <Col
                    span={11}
                    className="flex items-center justify-end text-[12px]"
                  >
                    {currentData?.dataMonthCycle?.[2]?.ranked_score ?? 0}
                  </Col>
                </Row>
                <Row>
                  <Col span={13} className="flex items-center">
                    <img
                      className="w-[12px] h-[12px] mr-1"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                      alt=""
                    />
                    <div className="text-[12px]">Exploration</div>
                  </Col>
                  <Col
                    span={11}
                    className="flex items-center justify-end text-[12px]"
                  >
                    {currentData?.dataMonthCycle?.[2]?.exploration_score ?? 0}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      )}
      <Row
        gutter={24}
        align="middle"
        className="mt-[15px]"
        style={{ fontFamily: 'Rexliafree' }}
      >
        <Col span={24} className="flex items-center justify-center">
          <CaretLeftOutlined
            onClick={goToPrevious}
            disabled={currentIndex === 0}
            style={{
              color: currentIndex === 0 ? '#004a5c' : '#00c8fa',
              fontSize: '40px',
              cursor: currentIndex === 0 ? 'not-allowed' : 'pointer',
            }}
          />
          <div className="text-[20px] ">Cycle {currentData.cycle}</div>
          <div className="text-[12px] ml-2">
            ({nextTwoMonths[0].month} - {nextTwoMonths[2].month})
          </div>
          <CaretRightOutlined
            onClick={goToNext}
            disabled={currentIndex === dataCycleGuildRank.length - 1}
            style={{
              color:
                currentIndex === dataCycleGuildRank.length - 1
                  ? '#004a5c'
                  : '#00c8fa',
              fontSize: '40px',
              cursor:
                currentIndex === dataCycleGuildRank.length - 1
                  ? 'not-allowed'
                  : 'pointer',
            }}
          />
        </Col>
      </Row>
    </>
  )
}

export default RankingGuild
