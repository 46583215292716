import {
  LOGIN_FAILED,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
  GET_NONCE_START,
  GET_NONCE_SUCCESS,
  GET_NONCE_FAILED,
  LOGIN_GAME_START,
  LOGIN_GAME_SUCCESS,
  LOGIN_GAME_FAILED,
  LOGOUT_GAME_START,
  LOGOUT_GAME_SUCCESS,
  LOGOUT_GAME_FAILED,
} from '../actions/auth'

const initialState = {
  isLoading: false,
  userInfo: {},
  userInfoGame: {},
}

/**
 * auth reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_START:
    case LOGOUT_START:
    case LOGOUT_GAME_START:
    case LOGIN_GAME_START:
    case GET_NONCE_START:
      return {
        ...state,
        isLoading: true,
      }
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userInfo: {},
      }
    case LOGOUT_GAME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userInfoGame: {},
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userInfo: action.payload,
      }
    case LOGIN_GAME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userInfoGame: action.payload,
      }
    case GET_NONCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case LOGIN_FAILED:
    case LOGIN_GAME_FAILED:
    case LOGOUT_GAME_FAILED:
    case LOGOUT_FAILED:
    case GET_NONCE_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state
  }
}
