import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
const ChartRankCaro = ({ participantsMatch_list, sortedListChartRank }) => {
  const rows = sortedListChartRank?.length
  const cols = sortedListChartRank?.length

  const [scores, setScores] = useState(Array(rows).fill(0))

  const [board, setBoard] = useState([])

  useEffect(() => {
    if (
      !sortedListChartRank ||
      sortedListChartRank.length === 0 ||
      !participantsMatch_list
    )
      return

    // Initialize the board as a matrix of empty strings
    const initialBoard = Array(rows)
      .fill(null)
      .map(() => Array(cols).fill(''))

    setBoard(initialBoard)

    participantsMatch_list.forEach((m) => {
      const player1Address = sortedListChartRank[m.player_1_id]?.address_wallet
      const player2Address = sortedListChartRank[m.player_2_id]?.address_wallet

      // Find a match between player1 and player2 using addresses
      const match =
        (m.player_1 === player1Address && m.player_2 === player2Address) ||
        (m.player_1 === player2Address && m.player_2 === player1Address)

      if (match) {
        const rowIndex = sortedListChartRank.findIndex(
          (player) => player.address_wallet === m.player_1,
        )
        const colIndex = sortedListChartRank.findIndex(
          (player) => player.address_wallet === m.player_2,
        )

        // Ensure valid indices
        if (rowIndex !== -1 && colIndex !== -1) {
          const updatedBoard = [...initialBoard]

          // Set '0' or 'X' based on the winner
          updatedBoard[rowIndex][colIndex] =
            m.winner === m.player_1_id ? 'O' : 'X'
          updatedBoard[colIndex][rowIndex] =
            m.winner === m.player_2_id ? 'O' : 'X'

          // Update the board
          setBoard(updatedBoard)
        }
      }
    })
  }, [participantsMatch_list, sortedListChartRank])

  useEffect(() => {
    // Calculate scores based on number of 'O's in each row (excluding matched cells)
    const calculatedScores = board.map((row, rowIndex) =>
      row.reduce((count, cell, colIndex) => {
        // Check if the cell is not matched (i.e., isMatchedUser is false)
        const isMatchedUser =
          sortedListChartRank[rowIndex]?._id ===
          sortedListChartRank[colIndex]?._id

        return count + (cell === 'O' && !isMatchedUser ? 1 : 0)
      }, 0),
    )

    setScores(calculatedScores)
  }, [board]) // Run this effect only once on mount
  const getGridItems = () => {
    let gridItems = []
    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < cols; col++) {
        const isMatchedUser =
          sortedListChartRank[row]?._id === sortedListChartRank[col]?._id
        const cellValue = board[row] ? board[row][col] : ''
        gridItems.push(
          <div
            key={`${row}-${col}`}
            className="checkerboard-cell"
            style={{
              backgroundColor: '#FFFFFF',
              width: '50px',
              height: '50px',
              border: '1px solid rgba(136, 136, 136, 1)',
              position: 'relative',
              boxSizing: 'border-box',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '24px', // Font size for X and O
              color:
                cellValue === 'X'
                  ? 'rgba(255, 0, 0, 1)'
                  : 'rgba(12, 200, 254, 1)',
            }}
          >
            {/* Add diagonal line if row player matches column player */}
            {isMatchedUser ? (
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '1px',
                  width: '140%',
                  borderTop: '2px solid rgba(136, 136, 136, 1)', // Diagonal line from top-left to bottom-right
                  transform: 'rotate(45deg)', // Rotate the line
                  transformOrigin: 'top left', // Make sure the line starts from top-left
                }}
              />
            ) : (
              cellValue // Use the safe cellValue
            )}
          </div>,
        )
      }
    }
    return gridItems
  }
  return (
    <div>
      {' '}
      <div className="flex justify-center">
        <div
          className="checkerboard-wrapper"
          style={{
            display: 'grid',
            gridTemplateColumns: `200px repeat(${cols}, 50px) 50px`, // Adjusting to account for wider left player labels and the score column
            gridTemplateRows: `50px repeat(${rows}, 50px)`, // Keep the same
          }}
        >
          {/* Top row for player labels */}
          <div className="text-[30px]">
            Round{' '}
            {Math.max(...participantsMatch_list.map((item) => item.round_id)) +
              1}
          </div>
          {/* Empty corner */}
          {sortedListChartRank.map((player, index) => (
            <div
              key={`top-${index}`}
              className="top-player-label"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '50px',
                height: '50px',
                fontWeight: 'bold',
                border: '1px solid #ccc', // Optional: borders to match grid
              }}
            >
              <img
                className=" w-[35px] h-[35px] rounded-full "
                src={player?.avatar ?? '../svgs/camera.svg'}
                alt=""
              />
            </div>
          ))}
          <div></div> {/* Empty space for the score column */}
          {/* Left column for player names, grid, and score on the right */}
          {sortedListChartRank.map((player, rowIndex) => (
            <React.Fragment key={`row-${rowIndex}`}>
              {/* Player name on the left */}
              <div
                className="left-player-label"
                style={{
                  width: '200px', // Updated width for player names
                  height: '50px',
                  fontWeight: 'bold',
                  border: '1px solid #ccc', // Optional: borders to match grid
                }}
              >
                <Row>
                  <Col span={6} className="flex items-center justify-center ">
                    <img
                      className=" w-[35px] h-[35px] mt-[13%] rounded-full "
                      src={player?.avatar ?? '../svgs/camera.svg'}
                      alt=""
                    />
                  </Col>
                  <Col span={18} className="flex items-center">
                    <div className="mt-[6%] text-ellipsis overflow-hidden whitespace-nowrap max-w-[200px]">
                      {player.user_name}
                    </div>
                  </Col>
                </Row>
              </div>

              {/* Checkerboard row */}
              {getGridItems().slice(rowIndex * cols, (rowIndex + 1) * cols)}

              {/* Score for each row */}
              <div
                className="score-label"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '50px', // Score column width
                  height: '50px',
                  border: '1px solid #ccc',
                  backgroundColor: '#f0f0f0', // Optional: background color for score column
                  fontWeight: 'bold',
                  color: '#333', // Optional: text color for score
                }}
              >
                {scores[rowIndex]}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ChartRankCaro
