import { call, put, takeLatest } from 'redux-saga/effects'
import {
  deleteBlackListsSuccess,
  deleteBlackListsFailed,
  DELETE_BLACKLIST_START,
} from '../../actions/blackList'
import { api } from '~/services/api'

/**
 * delete blacklist api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const deleteBlackListsApi = (params) => {
  const uri = `/sponsor-tournament-blacklist/${params}`
  return api.delete(uri)
}
/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doDeleteBlackLists(action) {
  try {
    const response = yield call(deleteBlackListsApi, action?.payload)
    yield put(deleteBlackListsSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(deleteBlackListsFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch delete blacklist
 */
export default function* watchDeleteBlackLists() {
  yield takeLatest(DELETE_BLACKLIST_START, doDeleteBlackLists)
}
