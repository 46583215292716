import { call, put, takeLatest } from 'redux-saga/effects'

import { api } from '~/services/api/gameApi'

import {
  loginGameFailed,
  loginGameSuccess,
  LOGIN_GAME_START,
} from '../../actions/auth'
import {
  AUTH_TOKEN_GAME,
  REFRESH_TOKEN_GAME,
  USER_INFO_GAME,
} from '~/common/constants/ApiConstant'
import { NOTIFICATION_DURATION } from '~/common/constants'
import { notification } from 'antd'

/**
 * Login game
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const loginGameApi = (params) => {
  const uri = `/api/user/walletlogin`
  return api.post(uri, params)
}

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doLoginGame(action) {
  try {
    const response = yield call(loginGameApi, action?.payload)
    if (response?.success === true) {
      const { data } = response
      if (!data) {
        notification.error({
          message: 'LOGIN FAILED',
          duration: NOTIFICATION_DURATION,
        })
        return
      }
      // Save token to local storage
      localStorage.setItem(AUTH_TOKEN_GAME, data.token)
      // Save refresh token to local storage
      localStorage.setItem(REFRESH_TOKEN_GAME, 'REFRESH_TOKEN_GAME')
      // Save user infomation to local storage
      localStorage.setItem(USER_INFO_GAME, JSON.stringify({ ...data.user }))

      yield put(loginGameSuccess({ ...data.user }))

      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess({ ...data.user })
      }
    } else {
      notification.error({
        message: 'LOGIN FAILED',
        duration: NOTIFICATION_DURATION,
      })
      throw response?.data?.message
    }
  } catch (error) {
    yield put(loginGameFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * Watch login
 */
export default function* watchLoginGame() {
  yield takeLatest(LOGIN_GAME_START, doLoginGame)
}
