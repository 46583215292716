import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getListTournamentSuccess,
  getListTournamentFailed,
  GET_LIST_TOURNAMENT_START,
} from '../../actions/sponsorTournament'
import { api } from '~/services/api/gameApi'

/**
 * get list  sponsor tournament api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getListTournamentApi = (props) => {
  var uri = `/api/game/sponsored/tournament/get`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetListTournament(action) {
  try {
    const response = yield call(getListTournamentApi, action?.payload)
    yield put(getListTournamentSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(getListTournamentFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get list sponsor tournament
 */
export default function* watchGetListTournament() {
  yield takeLatest(GET_LIST_TOURNAMENT_START, doGetListTournament)
}
