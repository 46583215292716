import PropTypes from 'prop-types'
import { useTranslation } from '~/common/hooks/useTranslation'
import {
  Form,
  Modal,
  Input,
  Button,
  notification,
  Row,
  Col,
  DatePicker,
} from 'antd'
import React, { useEffect, useState } from 'react'
import {
  NOTIFICATION_DURATION,
  cancelButtonStyle,
  okButtonStyle,
} from '~/common/constants'
import { REGEX } from '~/helpers/regex'
import moment from 'moment'
import { usePools } from '~/views/app/redux/hooks/usePools'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isBetween from 'dayjs/plugin/isBetween'
function RankingPoolForm(props) {
  const { t } = useTranslation()
  const { id, isShowModal, onClose, refreshData, ranking } = props
  const [isLoading, setIsloading] = useState(false)
  const [form] = Form.useForm()
  const [selectedTimeStart, setSelectedTimeStart] = useState(null)
  const rules = {
    poolName: [
      () => ({
        validator(rule, value) {
          const regex = new RegExp(REGEX.PROFILE_NAME)
          if (value && value?.charAt(0) === '_') {
            return Promise.reject(
              'Name is a string of maximum 15 characters, which may include Latin letters, Japanese characters, numbers, spaces, or underscores, but must begin with a Latin letter, Japanese character or number.',
            )
          }
          if (value && value?.indexOf('-') !== -1) {
            return Promise.reject(
              'Name is a string of maximum 15 characters, which may include Latin letters, Japanese characters, numbers, spaces, or underscores, but must begin with a Latin letter, Japanese character or number.',
            )
          }
          if (value && !regex.test(value)) {
            return Promise.reject(
              'Name is a string of maximum 15 characters, which may include Latin letters, Japanese characters, numbers, spaces, or underscores, but must begin with a Latin letter, Japanese character or number.',
            )
          }
          return Promise.resolve()
        },
      }),

      {
        required: true,
        message: t('validate.required', {
          0: t('managementPools.rankingRewardsPool.form.poolName'),
        }),
      },
    ],
    poolTotal: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementPools.rankingRewardsPool.form.poolTotal'),
        }),
      },
    ],
    tournament: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementPools.rankingRewardsPool.form.tournament'),
        }),
      },
    ],
    weekly: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementPools.rankingRewardsPool.form.weekly'),
        }),
      },
    ],
    pvp: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementPools.rankingRewardsPool.form.pvp'),
        }),
      },
    ],
    exploraion: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementPools.rankingRewardsPool.form.exploraion'),
        }),
      },
    ],
    guild: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementPools.rankingRewardsPool.form.guild'),
        }),
      },
    ],
    sector: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementPools.rankingRewardsPool.form.sector'),
        }),
      },
    ],
    from: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementPools.rankingRewardsPool.form.from'),
        }),
      },
    ],
    to: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementPools.rankingRewardsPool.form.to'),
        }),
      },
    ],
  }

  const {
    actions,
    data: { listPool, isLoadingCreatePool },
  } = usePools()
  dayjs.extend(utc)
  dayjs.extend(isBetween)
  const timeRanges = listPool.map((pool) => ({
    timeStart: dayjs.unix(pool.timeStart).utc().startOf('day'),
    timeEnd: dayjs.unix(pool.timeEnd).utc().endOf('day'),
  }))

  //disable date same start day, end day of listpool
  const disabledDateFrom = (current) => {
    if (!current) return false

    return timeRanges.some((range) =>
      current.isBetween(range.timeStart, range.timeEnd, null, '[]'),
    )
  }
  const disabledDateTo = (current) => {
    if (!current) return false

    // Check for overlapping ranges with existing pools
    const isInRange = timeRanges.some((range) =>
      current.isBetween(range.timeStart, range.timeEnd, null, '[]'),
    )

    // If a timeStart is selected, disable dates based on the selection
    if (selectedTimeStart) {
      const minDate = selectedTimeStart.clone().subtract(0, 'day') // Disable dates before the day prior to selectedTimeStart

      // Find the next available timeStart after selectedTimeStart
      const nextTimeStart = timeRanges.reduce((next, range) => {
        if (range.timeStart.isAfter(selectedTimeStart)) {
          return next
            ? range.timeStart.isBefore(next)
              ? range.timeStart
              : next
            : range.timeStart
        }
        return next
      }, null)

      // Allow selection till the day before the next available timeStart, or to the end of the current month if none exists
      const maxDate = nextTimeStart
      // Disable dates that are in the overlapping ranges or outside the allowed min/max date range
      return isInRange || current.isBefore(minDate) || current.isAfter(maxDate)
    }

    return isInRange
  }

  const handleTimeStartChange = (date) => {
    setSelectedTimeStart(date)
    form.resetFields(['to'])
  }

  const handleSubmit = (values) => {
    try {
      setIsloading(true)
      const newTimeStart = new Date(values?.from)
      const newTimeEnd = new Date(values?.to)
      newTimeStart.setUTCHours(0, 0, 0, 0)
      newTimeEnd.setUTCHours(23, 59, 59, 0)
      const timeStart = newTimeStart.getTime() / 1000
      const timeEnd = newTimeEnd.getTime() / 1000

      const formValue = {
        name: values?.poolName,
        totalReward: Number(values?.poolTotal),
        guild: values?.guild,
        sector: values?.sector,
        weekly: values?.weekly,
        personalPvP: values?.pvp,
        personalExploraion: values?.exploraion,
        tournament: values?.tournament,
        timeStart,
        timeEnd,
      }
      if (id) {
        actions?.editPool(
          {
            ...formValue,
            cycle: ranking?.cycle,
            totalReward: String(values?.poolTotal),
          },
          (res) => {
            if (res) {
              setTimeout(() => {
                onSuccess()
                setIsloading(false)
                refreshData()
              }, 3000)
            }
          },
        )
      } else {
        actions?.createPool(formValue, (res) => {
          if (res) {
            setTimeout(() => {
              onSuccess()
              setIsloading(false)
              refreshData()
            }, 3000)
          }
        })
      }
    } catch (error) {
      setIsloading(false)
    }
  }

  const onSuccess = () => {
    notification.success({
      message: id
        ? t('common.message.updateSuccessfully')
        : t('common.message.createSuccessfully'),
      duration: NOTIFICATION_DURATION,
    })
    onClose()
    refreshData()
  }

  const handleClose = () => {
    form.resetFields()
    setSelectedTimeStart(null)
    onClose()
  }

  useEffect(() => {
    if (id) {
      const formDetailData = {
        poolName: ranking?.name,
        poolTotal: ranking?.totalAmount,
        tournament: ranking?.subPools?.tournament?.percentage,
        weekly: ranking?.subPools?.weekly?.percentage,
        exploraion: ranking?.subPools?.personalExploraion?.percentage,
        pvp: ranking?.subPools?.personalPvP?.percentage,
        guild: ranking?.subPools?.guild?.percentage,
        sector: ranking?.subPools?.sector?.percentage,
        // from: ranking?.timeStart ? moment.unix(ranking?.timeStart) : null,
        from: ranking?.timeStart
          ? dayjs.unix(ranking?.timeStart).utc().startOf('day')
          : null,
        // to: ranking?.timeEnd ? moment.unix(ranking?.timeEnd) : null,
        to: ranking?.timeEnd
          ? dayjs.unix(ranking?.timeEnd).utc().endOf('day')
          : null,
      }

      form.setFieldsValue(formDetailData)
    }
  }, [id])
  return (
    <Modal
      title={
        id
          ? t('managementPools.rankingRewardsPool.editTitle')
          : t('managementPools.rankingRewardsPool.addTitle')
      }
      onCancel={handleClose}
      open={isShowModal}
      width={600}
      centered
      destroyOnClose
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        form={form}
        translate="yes"
        autoComplete="off"
        size="middle"
        validateTrigger={['onBlur', 'onChange']}
      >
        <Form.Item
          label={t('managementPools.rankingRewardsPool.form.poolName')}
          name="poolName"
          rules={rules.poolName}
          labelAlign="left"
        >
          <Input
            placeholder={t('managementPools.rankingRewardsPool.form.poolName')}
          />
        </Form.Item>

        <Form.Item
          label={t('managementPools.rankingRewardsPool.form.poolTotal')}
          name="poolTotal"
          rules={rules.poolTotal}
          labelAlign="left"
        >
          <Input
            placeholder={t('managementPools.rankingRewardsPool.form.poolTotal')}
          />
        </Form.Item>

        <Row gutter={100}>
          <Col span={12}>
            <Form.Item
              label={t('managementPools.rankingRewardsPool.form.tournament')}
              rules={rules.tournament}
              name="tournament"
              labelAlign="left"
            >
              <Input
                placeholder={t(
                  'managementPools.rankingRewardsPool.form.tournament',
                )}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('managementPools.rankingRewardsPool.form.weekly')}
              rules={rules.weekly}
              name="weekly"
              labelAlign="left"
            >
              <Input
                placeholder={t(
                  'managementPools.rankingRewardsPool.form.weekly',
                )}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={100}>
          <Col span={12}>
            <Form.Item
              label={t('managementPools.rankingRewardsPool.form.pvp')}
              rules={rules.pvp}
              name="pvp"
              labelAlign="left"
            >
              <Input
                placeholder={t('managementPools.rankingRewardsPool.form.pvp')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('managementPools.rankingRewardsPool.form.exploraion')}
              rules={rules.exploraion}
              name="exploraion"
              labelAlign="left"
            >
              <Input
                placeholder={t(
                  'managementPools.rankingRewardsPool.form.exploraion',
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={100}>
          <Col span={12}>
            <Form.Item
              label={t('managementPools.rankingRewardsPool.form.guild')}
              rules={rules.guild}
              name="guild"
              labelAlign="left"
            >
              <Input
                placeholder={t('managementPools.rankingRewardsPool.form.guild')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('managementPools.rankingRewardsPool.form.sector')}
              rules={rules.sector}
              name="sector"
              labelAlign="left"
            >
              <Input
                placeholder={t(
                  'managementPools.rankingRewardsPool.form.sector',
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={100}>
          <Col span={12}>
            <Form.Item
              label={t('managementPools.rankingRewardsPool.form.from')}
              rules={rules.from}
              name="from"
              labelAlign="left"
            >
              <DatePicker
                onChange={handleTimeStartChange}
                disabledDate={disabledDateFrom}
                className="dateRanking"
                style={{ background: '#fff', width: '100%', height: '43px' }}
                placeholder={t('managementPools.rankingRewardsPool.form.from')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('managementPools.rankingRewardsPool.form.to')}
              rules={rules.to}
              name="to"
              labelAlign="left"
            >
              <DatePicker
                disabledDate={disabledDateTo}
                className="dateRanking"
                style={{ background: '#fff', width: '100%', height: '43px' }}
                placeholder={t('managementPools.rankingRewardsPool.form.to')}
              />
            </Form.Item>
          </Col>
        </Row>

        <div key="buttons" className="flex justify-center">
          <Button key="cancel" onClick={handleClose} style={cancelButtonStyle}>
            {t('common.btn.cancel')}
          </Button>
          <Button
            className="border-hidden"
            key="ok"
            htmlType="submit"
            type="primary"
            style={okButtonStyle}
            disabled={isLoadingCreatePool}
            loading={isLoading}
          >
            {id ? t('common.btn.update') : t('common.btn.create')}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

RankingPoolForm.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default RankingPoolForm
