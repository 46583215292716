import {
  GET_LIST_GUILD_START,
  GET_LIST_GUILD_SUCCESS,
  GET_LIST_GUILD_FAILED,
  DELETE_GUILD_START,
  DELETE_GUILD_SUCCESS,
  DELETE_GUILD_FAILED,
} from '../actions/guild'

const initialState = {
  isLoading: false,
  listGuild: [],
  total: 0,
  isLoadingDelete: false,
}

/**
 * shop reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function guildManagement(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_GUILD_START:
    case DELETE_GUILD_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_LIST_GUILD_SUCCESS:
      return {
        ...state,
        // listGuild: addKeyToList(
        //   action.payload?.listGuild,
        //   action.payload?.page,
        //   action.payload?.pageSize,
        // ),
        listGuild: action?.payload,
        // total: action?.payload,
        isLoading: false,
      }
    case DELETE_GUILD_SUCCESS:
      return {
        ...state,
        isLoadingDelete: false,
      }

    case GET_LIST_GUILD_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case DELETE_GUILD_FAILED:
      return {
        ...state,
        isLoadingDelete: false,
      }
    default:
      return state
  }
}
