import { call, put, takeLatest } from 'redux-saga/effects'
import {
  deleteGuildSuccess,
  deleteGuildFailed,
  DELETE_GUILD_START,
} from '../../actions/guild'
import { api } from '~/services/api/gameApi'
// import { api } from '~/services/api/blockchainApi'

/**
 * delete guild api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const deleteGuildsApi = (props) => {
  var uri = `/api/game/guild/delete`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doDeleteGuilds(action) {
  try {
    const response = yield call(deleteGuildsApi, action?.payload)
    yield put(deleteGuildSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(deleteGuildFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch delete guild
 */
export default function* watchDeleteGuilds() {
  yield takeLatest(DELETE_GUILD_START, doDeleteGuilds)
}
