import { useTranslation } from '~/common/hooks/useTranslation'
import { Button, Col, Dropdown, Form, Row, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import TimeHelper from '~/helpers/time-helper'
import { formatAddress } from '~/helpers/common'
import configs from '~/configs'
import { useUser } from '~/views/app/redux/hooks/useUser'
import { converUserRankUser } from '~/common/constants'
import { DeleteFilled } from '@ant-design/icons'

const WhiteList = () => {
  const { t } = useTranslation()
  const [formSearch] = Form.useForm()
  const [whiteList, setWhiteList] = useState([])

  const {
    actions,
    data: { isLoading, listUser, total },
  } = useUser()

  const { page, pageSize, keyword, setPage, setPageSize, setKeyword } =
    useQueryState()

  //Control all modal
  const [modalForm, setModalForm] = useState({
    id: null,
    isOpenModal: false,
    user: {},
  })

  const columns = [
    {
      title: t('common.no'),
      dataIndex: 'key',
      key: 'key',
      render: (data) => {
        return data
      },
    },
    {
      title: t('managementUsers.form.name'),
      dataIndex: 'user_name',
      key: 'user_name',
      render: (user_name) => {
        return <div className="">{user_name}</div>
      },
    },
    {
      title: t('managementUsers.form.walletAddress'),
      dataIndex: 'address_wallet',
      key: 'address_wallet',
      render: (address_wallet) => {
        let link = `${configs.NETWORK.scan}address/${address_wallet}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            title={address_wallet}
            style={{ color: '#40A9FF' }}
          >
            {address_wallet}
          </a>
        )
      },
    },
    {
      title: t('common.action'),
      render: (_, data) => {
        return (
          <Space>
            <Button
              type="ghost"
              loading={false}
              icon={<DeleteFilled style={{ color: '#FF4D4F' }} />}
              // onClick={() => onShowModalForm(data?._id, data)}
            />
          </Space>
        )
      },
    },
  ]

  //Refresh data
  const refreshData = () => {
    const params = {
      search: keyword,
      pageSize: pageSize,
      page,
    }
    actions.getListUser(params)
  }
  // Handle open modal (create-edit-delete)
  const onShowModalForm = (id, user) => {
    setModalForm({
      id,
      isOpenModal: true,
      user: user,
    })
  }

  //   // Handle close modal (create-edit-delete)
  const onCloseModalForm = () => {
    setModalForm({
      id: null,
      isOpenModal: false,
      user: {},
    })
  }

  // const handleChangeRole = (e) => {
  //   setRole(USER_ROLE[e.key])
  // }
  const handleSearch = (values) => {
    setKeyword(values?.keyword)
  }

  useEffect(() => {
    refreshData()
  }, [page, pageSize, keyword])

  const generateItemList = (itemList) =>
    itemList?.map((item) => ({
      key: item?._id,
      // label: item?.user_name,
      label: (
        <Row>
          <Col span={24}>Name: {item?.user_name}</Col>
          <Col span={24}>Wallet address: {item?.address_wallet}</Col>
        </Row>
      ),
      value: item?._id,
      name: item?.user_name,
    }))

  const handleAddGift = (value) => {
    const selectedUser = listUser.find((item) => item._id === value)
    if (selectedUser) {
      const formData = {
        id: selectedUser._id,
        name: selectedUser.user_name,
      }
      setWhiteList((old) => [formData])
      formSearch.resetFields()
    }
  }
  return (
    <div>
      <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <div className="flex justify-center align-center">
            <Form
              onFinish={handleSearch}
              className="w-[80%] "
              form={formSearch}
              translate="yes"
              autoComplete="off"
              size="middle"
              validateTrigger={['onBlur', 'onChange']}
              requiredMark={false}
            >
              <Form.Item
                className="w-full"
                label={
                  <span className="font__M__plus font-bold">
                    {t('common.add')}
                  </span>
                }
                name="keyword"
                labelAlign="left"
              >
                <Select
                  className="w-full addUserList"
                  onChange={handleAddGift}
                  options={generateItemList(listUser)}
                  placeholder={t('common.add')}
                  showSearch
                  filterOption={(input, option) =>
                    option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <div className="flex sm:justify-end w-full">
            <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end"></div>
          </div>
        </Col>
      </Row>
      <Datatable
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={listUser}
        loading={isLoading}
        showPagination
        showHeader
        metadata={{
          perPage: pageSize,
          setPerPage: setPageSize,
          page,
          setPage,
          total: total,
          keyword,
          setKeyword,
        }}
      />

      {/* {modalForm.isOpenModal && (
        <Profile
          isShowModal={modalForm.isOpenModal}
          onClose={onCloseModalForm}
          refreshData={refreshData}
          id={modalForm.id}
          user={modalForm?.user}
        />
      )} */}
    </div>
  )
}

export default WhiteList
