import { call, put, takeLatest } from 'redux-saga/effects'
import {
  createPoolSuccess,
  createPoolFailed,
  CREATE_POOL_START
} from '../../actions/pools'
import { api } from '~/services/api'

/**
 * create pools api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const createPoolApi = (props) => {
  var uri = `/admin/pools/create`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doCreatePools(action) {
  try {
    const response = yield call(createPoolApi, action?.payload)
    yield put(createPoolSuccess(response))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(createPoolFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch create pools
 */
export default function* watchCreatePool() {
  yield takeLatest(CREATE_POOL_START, doCreatePools)
}
