import { call, put, takeLatest } from 'redux-saga/effects'
import {
  closePoolSuccess,
  closePoolFailed,
  CLOSE_POOL_START
} from '../../actions/pools'
import { api } from '~/services/api'

/**
 * close pool api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const closePoolApi = (props) => {
  var uri = `/admin/pools/close`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doClosePools(action) {
  try {
    const response = yield call(closePoolApi, action?.payload)
    yield put(closePoolSuccess(response))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(closePoolFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch close pools
 */
export default function* watchClosePool() {
  yield takeLatest(CLOSE_POOL_START, doClosePools)
}
