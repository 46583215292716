import { call, put, takeLatest } from 'redux-saga/effects'
import { api } from '~/services/api/gameApi'
import {
  getScoreByWalletSuccess,
  getScoreByWalletFailed,
  GET_USER_SCORE_BY_WALLET_START,
} from '~/views/app/redux/actions/user'

/**
 * get score by wallet api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */

const getScoreByWalletApi = (props) => {
  var uri = `/api/game/user-score/get-by-wallet-type`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */

function* doGetScoreByWallet(action) {
  try {
    const response = yield call(getScoreByWalletApi, action?.payload)
    yield put(getScoreByWalletSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response?.data)
    }
  } catch (error) {
    yield put(getScoreByWalletFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}
/**
 * [Watcher]
 * Watch get score by wallet
 */
export default function* watchGetScoreByWallet() {
  yield takeLatest(GET_USER_SCORE_BY_WALLET_START, doGetScoreByWallet)
}
