export const GET_LIST_USER_START = 'GET_LIST_USER_START'
export const GET_LIST_USER_SUCCESS = 'GET_LIST_USER_SUCCESS'
export const GET_LIST_USER_FAILED = 'GET_LIST_USER_FAILED'

export const GET_DETAIL_USER_START = 'GET_DETAIL_USER_START'
export const GET_DETAIL_USER_SUCCESS = 'GET_DETAIL_USER_SUCCESS'
export const GET_DETAIL_USER_FAILED = 'GET_DETAIL_USER_FAILED'

export const GET_USER_BY_WALLET_START = 'GET_USER_BY_WALLET_START'
export const GET_USER_BY_WALLET_SUCCESS = 'GET_USER_BY_WALLET_SUCCESS'
export const GET_USER_BY_WALLET_FAILED = 'GET_USER_BY_WALLET_FAILED'

export const GET_USER_SCORE_BY_WALLET_START = 'GET_USER_SCORE_BY_WALLET_START'
export const GET_USER_SCORE_BY_WALLET_SUCCESS =
  'GET_USER_SCORE_BY_WALLET_SUCCESS'
export const GET_USER_SCORE_BY_WALLET_FAILED = 'GET_USER_SCORE_BY_WALLET_FAILED'

export const GET_BALANCEPOC_START = 'GET_BALANCEPOC_START'
export const GET_BALANCEPOC_SUCCESS = 'GET_BALANCEPOC_SUCCESS'
export const GET_BALANCEPOC_FAILED = 'GET_BALANCEPOC_FAILED'

export const GET_LIST_SCORE_ADDRESS_START = 'GET_LIST_SCORE_ADDRESS_START'
export const GET_LIST_SCORE_ADDRESS_SUCCESS = 'GET_LIST_SCORE_ADDRESS_SUCCESS'
export const GET_LIST_SCORE_ADDRESS_FAILED = 'GET_LIST_SCORE_ADDRESS_FAILED'

export const GET_LIST_MONSTER_ADDRESS_START = 'GET_LIST_MONSTER_ADDRESS_START'
export const GET_LIST_MONSTER_ADDRESS_SUCCESS =
  'GET_LIST_MONSTER_ADDRESS_SUCCESS'
export const GET_LIST_MONSTER_ADDRESS_FAILED = 'GET_LIST_MONSTER_ADDRESS_FAILED'

export const GET_LIST_SNAPSHOT_ADDRESS_START = 'GET_LIST_SNAPSHOT_ADDRESS_START'
export const GET_LIST_SNAPSHOT_ADDRESS_SUCCESS =
  'GET_LIST_SNAPSHOT_ADDRESS_SUCCESS'
export const GET_LIST_SNAPSHOT_ADDRESS_FAILED =
  'GET_LIST_SNAPSHOT_ADDRESS_FAILED'

//-------------------------user------------------------//
/**
 * [1]
 * get list user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getListUser(payload, onSuccess, onError) {
  return {
    type: GET_LIST_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get list user success action
 * @param {*} payload
 * @returns {object}
 */
export function getListUserSuccess(payload) {
  return {
    type: GET_LIST_USER_SUCCESS,
    payload: payload,
  }
}

/**
 * get list user failed action
 * @returns {object}
 */
export function getListUserFailed() {
  return {
    type: GET_LIST_USER_FAILED,
  }
}

/**
 * [2]
 * get detail user start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getDetailUser(payload, onSuccess, onError) {
  return {
    type: GET_DETAIL_USER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get detail user success action
 * @param {*} payload
 * @returns {object}
 */
export function getDetailUserSuccess(payload) {
  return {
    type: GET_DETAIL_USER_SUCCESS,
    payload: payload,
  }
}

/**
 * get detail user failed action
 * @returns {object}
 */
export function getDetailUserFailed() {
  return {
    type: GET_DETAIL_USER_FAILED,
  }
}

/**
 * [3]
 * get user by wallet start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getUserByWallet(payload, onSuccess, onError) {
  return {
    type: GET_USER_BY_WALLET_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get user by wallet success action
 * @param {*} payload
 * @returns {object}
 */
export function getUserByWalletSuccess(payload) {
  return {
    type: GET_USER_BY_WALLET_SUCCESS,
    payload: payload,
  }
}

/**
 *  get user by wallet failed action
 * @returns {object}
 */
export function getUserByWalletFailed() {
  return {
    type: GET_USER_BY_WALLET_FAILED,
  }
}

/**
 * [4]
 * get user by score start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getScoreByWallet(payload, onSuccess, onError) {
  return {
    type: GET_USER_SCORE_BY_WALLET_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get user by score success action
 * @param {*} payload
 * @returns {object}
 */
export function getScoreByWalletSuccess(payload) {
  return {
    type: GET_USER_SCORE_BY_WALLET_SUCCESS,
    payload: payload,
  }
}

/**
 *  get user by score failed action
 * @returns {object}
 */
export function getScoreByWalletFailed() {
  return {
    type: GET_USER_SCORE_BY_WALLET_FAILED,
  }
}

/**
 * [5]
 * get balancePoc start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getBalacePoc(payload, onSuccess, onError) {
  return {
    type: GET_BALANCEPOC_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get balancePoc success action
 * @param {*} payload
 * @returns {object}
 */
export function getBalacePocSuccess(payload) {
  return {
    type: GET_BALANCEPOC_SUCCESS,
    payload: payload,
  }
}

/**
 *  get balancePoc failed action
 * @returns {object}
 */
export function getBalacePocFailed() {
  return {
    type: GET_BALANCEPOC_FAILED,
  }
}

/**
 * [6]
 * get score address start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getListScoreAddress(payload, onSuccess, onError) {
  return {
    type: GET_LIST_SCORE_ADDRESS_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get score address success action
 * @param {*} payload
 * @returns {object}
 */
export function getListScoreAddressSuccess(payload) {
  return {
    type: GET_LIST_SCORE_ADDRESS_SUCCESS,
    payload: payload,
  }
}

/**
 *  get score address failed action
 * @returns {object}
 */
export function getListScoreAddressFailed() {
  return {
    type: GET_LIST_SCORE_ADDRESS_FAILED,
  }
}

/**
 * [7]
 * get monster address start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getListMonsterAddressWallet(payload, onSuccess, onError) {
  return {
    type: GET_LIST_MONSTER_ADDRESS_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get monster address success action
 * @param {*} payload
 * @returns {object}
 */
export function getListMonsterAddressWalletSuccess(payload) {
  return {
    type: GET_LIST_MONSTER_ADDRESS_SUCCESS,
    payload: payload,
  }
}

/**
 *  get monster address failed action
 * @returns {object}
 */
export function getListMonsterAddressWalletFailed() {
  return {
    type: GET_LIST_MONSTER_ADDRESS_FAILED,
  }
}

/**
 * [8]
 * get score snapshot address start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getScoreSnapShotAddress(payload, onSuccess, onError) {
  return {
    type: GET_LIST_SNAPSHOT_ADDRESS_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get score snapshot address success action
 * @param {*} payload
 * @returns {object}
 */
export function getScoreSnapShotAddressSuccess(payload) {
  return {
    type: GET_LIST_SNAPSHOT_ADDRESS_SUCCESS,
    payload: payload,
  }
}

/**
 *  get score snapshot address failed action
 * @returns {object}
 */
export function getScoreSnapShotAddressFailed() {
  return {
    type: GET_LIST_SNAPSHOT_ADDRESS_FAILED,
  }
}

export default {
  getListUser,
  getListUserSuccess,
  getListUserFailed,

  getDetailUser,
  getDetailUserSuccess,
  getDetailUserFailed,

  getUserByWallet,
  getUserByWalletSuccess,
  getUserByWalletFailed,

  getScoreByWallet,
  getScoreByWalletSuccess,
  getScoreByWalletFailed,

  getBalacePoc,
  getBalacePocSuccess,
  getBalacePocFailed,

  getListScoreAddress,
  getListScoreAddressSuccess,
  getListScoreAddressFailed,

  getListMonsterAddressWallet,
  getListMonsterAddressWalletSuccess,
  getListMonsterAddressWalletFailed,

  getScoreSnapShotAddress,
  getScoreSnapShotAddressSuccess,
  getScoreSnapShotAddressFailed,
}
