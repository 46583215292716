import { Col, Row } from 'antd'
import {
  ContractEnhanceItem,
  ContractFusionItem,
  ContractRegenerationItem,
  ContractTrainingItem,
} from '~/blockchain/contract'
import configs from '~/configs'
const TraningItem = [
  {
    id: 'ENERGY_BANANA_C',
    name: 'Energy Banana',
    quanlity: 'C',
    image: 'Energy banana',
  },
  {
    id: 'ENERGY_BANANA_UC',
    name: 'Energy Banana',
    quanlity: 'UC',
    image: 'Energy banana',
  },
  {
    id: 'ENERGY_BANANA_R',
    name: 'Energy Banana',
    quanlity: 'R',
    image: 'Energy banana',
  },
  {
    id: 'ENERGY_BANANA_SHOP',
    name: 'Energy Banana',
    quanlity: 'S',
    image: 'Energy banana',
  },
  {
    id: 'REFRESH_HERB_C',
    name: 'Refresh Herb',
    quanlity: 'C',
    image: 'Refresh herb',
  },
  {
    id: 'REFRESH_HERB_UC',
    name: 'Refresh Herb',
    quanlity: 'UC',
    image: 'Refresh herb',
  },
  {
    id: 'REFRESH_HERB_R',
    name: 'Refresh Herb',
    quanlity: 'R',
    image: 'Refresh herb',
  },
  {
    id: 'REFRESH_HERB_SHOP',
    name: 'Refresh Herb',
    quanlity: 'S',
    image: 'Refresh herb',
  },
  {
    id: 'FRESH_MILK_C',
    name: 'Fresh Milk',
    quanlity: 'C',
    image: 'Fresh Milk',
  },
  {
    id: 'FRESH_MILK_UC',
    name: 'Fresh Milk',
    quanlity: 'UC',
    image: 'Fresh Milk',
  },
  {
    id: 'FRESH_MILK_R',
    name: 'Fresh Milk',
    quanlity: 'R',
    image: 'Fresh Milk',
  },
  {
    id: 'FRESH_MILK_SHOP',
    name: 'Fresh Milk',
    quanlity: 'S',
    image: 'Fresh Milk',
  },
  {
    id: 'FAIRY_BERRY_C',
    name: 'Fairy Berry',
    quanlity: 'C',
    image: 'Fairy berry',
  },
  {
    id: 'FAIRY_BERRY_UC',
    name: 'Fairy Berry',
    quanlity: 'UC',
    image: 'Fairy berry',
  },
  {
    id: 'FAIRY_BERRY_R',
    name: 'Fairy Berry',
    quanlity: 'R',
    image: 'Fairy berry',
  },
  {
    id: 'FAIRY_BERRY_SHOP',
    name: 'Fairy Berry',
    quanlity: 'S',
    image: 'Fairy berry',
  },
  {
    id: 'CARAMEL_CAKE_C',
    name: 'Caramel Cake',
    quanlity: 'C',
    image: 'Caramel Cake',
  },
  {
    id: 'CARAMEL_CAKE_UC',
    name: 'Caramel Cake',
    quanlity: 'UC',
    image: 'Caramel Cake',
  },
  {
    id: 'CARAMEL_CAKE_R',
    name: 'Caramel Cake',
    quanlity: 'R',
    image: 'Caramel Cake',
  },
  {
    id: 'CARAMEL_CAKE_SHOP',
    name: 'Caramel Cake',
    quanlity: 'S',
    image: 'Caramel Cake',
  },
  {
    id: 'CHIA_YOGURT_C',
    name: 'Chia Yogurt',
    quanlity: 'C',
    image: 'Chia Yogurt',
  },
  {
    id: 'CHIA_YOGURT_UC',
    name: 'Chia Yogurt',
    quanlity: 'UC',
    image: 'Chia Yogurt',
  },
  {
    id: 'CHIA_YOGURT_R',
    name: 'Chia Yogurt',
    quanlity: 'R',
    image: 'Chia Yogurt',
  },
  {
    id: 'CHIA_YOGURT_SHOP',
    name: 'Chia Yogurt',
    quanlity: 'S',
    image: 'Chia Yogurt',
  },
  {
    id: 'SATIETY_KONJACT_C',
    name: 'Satiety Konjact',
    quanlity: 'C',
    image: 'Satiety Konjac',
  },
  {
    id: 'SATIETY_KONJACT_UC',
    name: 'Satiety Konjact',
    quanlity: 'UC',
    image: 'Satiety Konjac',
  },
  {
    id: 'SATIETY_KONJACT_R',
    name: 'Satiety Konjact',
    quanlity: 'R',
    image: 'Satiety Konjac',
  },
  {
    id: 'SATIETY_KONJACT_SHOP',
    name: 'Satiety Konjact',
    quanlity: 'S',
    image: 'Satiety Konjac',
  },
  {
    id: 'GLORIOUS_MEAT_C',
    name: 'Glorious Meat',
    quanlity: 'C',
    image: 'Glorious Meat',
  },
  {
    id: 'GLORIOUS_MEAT_UC',
    name: 'Glorious Meat',
    quanlity: 'UC',
    image: 'Glorious Meat',
  },
  {
    id: 'GLORIOUS_MEAT_R',
    name: 'Glorious Meat',
    quanlity: 'R',
    image: 'Glorious Meat',
  },
  {
    id: 'GLORIOUS_MEAT_SHOP',
    name: 'Glorious Meat',
    quanlity: 'S',
    image: 'Glorious Meat',
  },
  {
    id: 'SUNNY_BLOSSOM_C',
    name: 'Sunny Blossom',
    quanlity: 'C',
    image: 'Sunny Blossom',
  },
  {
    id: 'SUNNY_BLOSSOM_UC',
    name: 'Sunny Blossom',
    quanlity: 'UC',
    image: 'Sunny Blossom',
  },
  {
    id: 'SUNNY_BLOSSOM_R',
    name: 'Sunny Blossom',
    quanlity: 'R',
    image: 'Sunny Blossom',
  },
  {
    id: 'SUNNY_BLOSSOM_SHOP',
    name: 'Sunny Blossom',
    quanlity: 'S',
    image: 'Sunny Blossom',
  },
  {
    id: 'LUNAR_GRASS_C',
    name: 'Lunar Grass',
    quanlity: 'C',
    image: 'Lunar Grass',
  },
  {
    id: 'LUNAR_GRASS_UC',
    name: 'Lunar Grass',
    quanlity: 'UC',
    image: 'Lunar Grass',
  },
  {
    id: 'LUNAR_GRASS_R',
    name: 'Lunar Grass',
    quanlity: 'R',
    image: 'Lunar Grass',
  },
  {
    id: 'LUNAR_GRASS_SHOP',
    name: 'Lunar Grass',
    quanlity: 'S',
    image: 'Lunar Grass',
  },
  {
    id: 'LIFE_WATER_C',
    name: 'Life Water',
    quanlity: 'C',
    image: 'Life Water',
  },
  {
    id: 'LIFE_WATER_UC',
    name: 'Life Water',
    quanlity: 'UC',
    image: 'Life Water',
  },
  {
    id: 'LIFE_WATER_R',
    name: 'Life Water',
    quanlity: 'R',
    image: 'Life Water',
  },
  {
    id: 'TOURNAMENT_TICKET_UC',
    name: 'Tournament Ticket',
    quanlity: 'UC',
    image: 'Tournament Ticket',
  },
  {
    id: 'EXPLORATION_TICKET_UC',
    name: 'Exploration Ticket',
    quanlity: 'UC',
    image: 'Exploration Ticket',
  },
  {
    id: 'FLOWER_CROWN_C',
    name: 'Flower Crowm',
    quanlity: 'C',
    image: 'Flower Crown',
  },
  {
    id: 'DEKAUSA_DOLL_C',
    name: 'Dekausa Doll',
    quanlity: 'C',
    image: 'Dekausa Doll',
  },
  {
    id: 'SILK_HANDKERCHIEF_C',
    name: 'Silk Handkerchief',
    quanlity: 'C',
    image: 'Silk Handkerchief',
  },
  {
    id: 'NUTS_C',
    name: 'Nuts',
    quanlity: 'C',
    image: 'Nuts',
  },
  {
    id: 'PRETTY_STONE_C',
    name: 'Pretty Stone',
    quanlity: 'C',
    image: 'Pretty_Stone',
  },
  {
    id: 'SILVER_INGOT_UC',
    name: 'Silver Ingot',
    quanlity: 'UC',
    image: 'Metal Ingot',
  },
  {
    id: 'GOLD_INGOT_UC',
    name: 'Gold Ingot',
    quanlity: 'UC',
    image: 'Gold Ingot',
  },
  {
    id: 'GEM_BAG_R',
    name: 'Gem Bag',
    quanlity: 'R',
    image: 'Gem bag',
  },
  {
    id: 'TOURNAMENT_TICKET_B',
    name: 'Tournament Ticket',
    quanlity: 'B',
    image: 'Tournament Ticket',
  },
  {
    id: 'EXPLORATION_TICKET_B',
    name: 'Exploration Ticket',
    quanlity: 'B',
    image: 'Exploration Ticket',
  },
  {
    id: 'ENERGY_BANANA_B',
    name: 'Energy Banana',
    quanlity: 'B',
    image: 'Energy banana',
  },
  {
    id: 'REFRESH_HERB_B',
    name: 'Refresh Herb',
    quanlity: 'B',
    image: 'Refresh herb',
  },
  {
    id: 'FAIRY_BERRY_B',
    name: 'Fairy Berry',
    quanlity: 'B',
    image: 'Fairy berry',
  },
  {
    id: 'FRESH_MILK_B',
    name: 'Fresh Milk',
    quanlity: 'B',
    image: 'Fresh Milk',
  },
]

const EnhanceItem = [
  {
    id: 'HP_SEED_C',
    name: 'HP Seed',
    quanlity: 'C',
    image: 'HP Seed',
  },
  {
    id: 'HP_SEED_UC',
    name: 'HP Seed',
    quanlity: 'UC',
    image: 'HP Seed',
  },
  {
    id: 'HP_SEED_R',
    name: 'HP Seed',
    quanlity: 'R',
    image: 'HP Seed',
  },
  {
    id: 'STR_SEED_C',
    name: 'STR Seed',
    quanlity: 'C',
    image: 'STR Seed',
  },
  {
    id: 'STR_SEED_UC',
    name: 'STR Seed',
    quanlity: 'UC',
    image: 'STR Seed',
  },
  {
    id: 'STR_SEED_R',
    name: 'STR Seed',
    quanlity: 'R',
    image: 'STR Seed',
  },
  {
    id: 'INT_SEED_C',
    name: 'INT Seed',
    quanlity: 'C',
    image: 'INT Seed',
  },
  {
    id: 'INT_SEED_UC',
    name: 'INT Seed',
    quanlity: 'UC',
    image: 'INT Seed',
  },
  {
    id: 'INT_SEED_R',
    name: 'INT Seed',
    quanlity: 'R',
    image: 'INT Seed',
  },
  {
    id: 'DEX_SEED_C',
    name: 'DEX Seed',
    quanlity: 'C',
    image: 'DEX Seed',
  },
  {
    id: 'DEX_SEED_UC',
    name: 'DEX Seed',
    quanlity: 'UC',
    image: 'DEX Seed',
  },
  {
    id: 'DEX_SEED_R',
    name: 'DEX Seed',
    quanlity: 'R',
    image: 'DEX Seed',
  },
  {
    id: 'AGI_SEED_C',
    name: 'AGI Seed',
    quanlity: 'C',
    image: 'AGI Seed',
  },
  {
    id: 'AGI_SEED_UC',
    name: 'AGI Seed',
    quanlity: 'UC',
    image: 'AGI Seed',
  },
  {
    id: 'AGI_SEED_R',
    name: 'AGI Seed',
    quanlity: 'R',
    image: 'AGI Seed',
  },
  {
    id: 'VIT_SEED_C',
    name: 'VIT Seed',
    quanlity: 'C',
    image: 'VIT Seed',
  },
  {
    id: 'VIT_SEED_UC',
    name: 'VIT Seed',
    quanlity: 'UC',
    image: 'VIT Seed',
  },
  {
    id: 'VIT_SEED_R',
    name: 'VIT Seed',
    quanlity: 'R',
    image: 'VIT Seed',
  },
  {
    id: 'ALL_SEED_C',
    name: 'ALL Seed',
    quanlity: 'C',
    image: 'Golden Seed',
  },
  {
    id: 'ALL_SEED_UC',
    name: 'ALL Seed',
    quanlity: 'UC',
    image: 'Golden Seed',
  },
  {
    id: 'ALL_SEED_R',
    name: 'ALL Seed',
    quanlity: 'R',
    image: 'Golden Seed',
  },

  {
    id: 'STAMINA_GUIDE_BOOK_C',
    name: 'Stamina guidebook',
    quanlity: 'C',
    image: 'Stamina Guidebook',
  },
  {
    id: 'STAMINA_GUIDE_BOOK_UC',
    name: 'Stamina guidebook',
    quanlity: 'UC',
    image: 'Stamina Guidebook',
  },
  {
    id: 'STAMINA_GUIDE_BOOK_R',
    name: 'Stamina guidebook',
    quanlity: 'R',
    image: 'Stamina Guidebook',
  },
  {
    id: 'STRENGTH_GUIDE_BOOK_C',
    name: 'Strength guidebook',
    quanlity: 'C',
    image: 'Strength Guidebook',
  },
  {
    id: 'STRENGTH_GUIDE_BOOK_UC',
    name: 'Strength guidebook',
    quanlity: 'UC',
    image: 'Strength Guidebook',
  },
  {
    id: 'STRENGTH_GUIDE_BOOK_R',
    name: 'Strength guidebook',
    quanlity: 'R',
    image: 'Strength Guidebook',
  },
  {
    id: 'WISDOM_GUIDE_BOOK_C',
    name: 'Wisdom guidebook',
    quanlity: 'C',
    image: 'Wisdom Guidebook',
  },
  {
    id: 'WISDOM_GUIDE_BOOK_UC',
    name: 'Wisdom guidebook',
    quanlity: 'UC',
    image: 'Wisdom Guidebook',
  },
  {
    id: 'WISDOM_GUIDE_BOOK_R',
    name: 'Wisdom guidebook',
    quanlity: 'R',
    image: 'Wisdom Guidebook',
  },
  {
    id: 'ACCURACY_GUIDE_BOOK_C',
    name: 'Accuracy guidebook',
    quanlity: 'C',
    image: 'Accuracy Guidebook',
  },
  {
    id: 'ACCURACY_GUIDE_BOOK_UC',
    name: 'Accuracy guidebook',
    quanlity: 'UC',
    image: 'Accuracy Guidebook',
  },
  {
    id: 'ACCURACY_GUIDE_BOOK_R',
    name: 'Accuracy guidebook',
    quanlity: 'R',
    image: 'Accuracy Guidebook',
  },
  {
    id: 'AVOIDANCE_GUIDE_BOOK_C',
    name: 'Avoidance guidebook',
    quanlity: 'C',
    image: 'Avoidance Guidebook',
  },
  {
    id: 'AVOIDANCE_GUIDE_BOOK_UC',
    name: 'Avoidance guidebook',
    quanlity: 'UC',
    image: 'Avoidance Guidebook',
  },
  {
    id: 'AVOIDANCE_GUIDE_BOOK_R',
    name: 'Avoidance guidebook',
    quanlity: 'R',
    image: 'Avoidance Guidebook',
  },
  {
    id: 'DEFENSE_GUIDE_BOOK_C',
    name: 'Defense guidebook',
    quanlity: 'C',
    image: 'Defense Guidebook',
  },
  {
    id: 'DEFENSE_GUIDE_BOOK_UC',
    name: 'Defense guidebook',
    quanlity: 'UC',
    image: 'Defense Guidebook',
  },
  {
    id: 'DEFENSE_GUIDE_BOOK_R',
    name: 'Defense guidebook',
    quanlity: 'R',
    image: 'Defense Guidebook',
  },
  {
    id: 'ULTIMANIA_C',
    name: 'Ultimania',
    quanlity: 'C',
    image: 'Ultimania',
  },
  {
    id: 'ULTIMANIA_UC',
    name: 'Ultimania',
    quanlity: 'UC',
    image: 'Ultimania',
  },
  {
    id: 'ULTIMANIA_R',
    name: 'Ultimania',
    quanlity: 'R',
    image: 'Ultimania',
  },

  {
    id: 'REFRESHING_AROMA_C',
    name: 'Refreshing aroma',
    quanlity: 'C',
    image: 'Refreshing aroma',
  },
  {
    id: 'REFRESHING_AROMA_UC',
    name: 'Refreshing aroma',
    quanlity: 'UC',
    image: 'Refreshing aroma',
  },
  {
    id: 'REFRESHING_AROMA_R',
    name: 'Refreshing aroma',
    quanlity: 'R',
    image: 'Refreshing aroma',
  },
  {
    id: 'PITCHER_OF_PRAYER_C',
    name: 'Pitcher of prayer',
    quanlity: 'C',
    image: 'Pitcher of prayer',
  },
  {
    id: 'PITCHER_OF_PRAYER_UC',
    name: 'Pitcher of prayer',
    quanlity: 'UC',
    image: 'Pitcher of prayer',
  },
  {
    id: 'PITCHER_OF_PRAYER_R',
    name: 'Pitcher of prayer',
    quanlity: 'R',
    image: 'Pitcher of prayer',
  },
  {
    id: 'INCENSE_BURNER_OF_TRANQUILITY_C',
    name: 'Incense burner of tranquility',
    quanlity: 'C',
    image: 'Incense burner of tranquility',
  },
  {
    id: 'INCENSE_BURNER_OF_TRANQUILITY_UC',
    name: 'Incense burner of tranquility',
    quanlity: 'UC',
    image: 'Incense burner of tranquility',
  },
  {
    id: 'INCENSE_BURNER_OF_TRANQUILITY_R',
    name: 'Incense burner of tranquility',
    quanlity: 'R',
    image: 'Incense burner of tranquility',
  },
  {
    id: 'COMFORTABLE_INTERIOR_SET_C',
    name: 'Comfortable interior set',
    quanlity: 'C',
    image: 'Comfortable interior set',
  },
  {
    id: 'COMFORTABLE_INTERIOR_SET_UC',
    name: 'Comfortable interior set',
    quanlity: 'UC',
    image: 'Comfortable interior set',
  },
  {
    id: 'COMFORTABLE_INTERIOR_SET_R',
    name: 'Comfortable interior set',
    quanlity: 'R',
    image: 'Comfortable interior set',
  },

  {
    id: 'PITCHER_OF_ANGEL_R',
    name: 'Pitcher of Angel',
    quanlity: 'R',
    image: 'Pitcher of Angel',
  },
  {
    id: 'PREMIUM_AROMA_R',
    name: 'Premium Aroma',
    quanlity: 'R',
    image: 'Premium Aroma',
  },
  {
    id: 'ALL_SEED_B',
    name: 'ALL Seed',
    quanlity: 'B',
    image: 'Golden Seed',
  },
  {
    id: 'GUIDE_BOOK_B',
    name: 'Guidebook',
    quanlity: 'B',
    image: 'Stamina Guidebook',
  },
  {
    id: 'PITCHER_OF_PRAYER_B',
    name: 'Pitcher of prayer',
    quanlity: 'B',
    image: 'Pitcher of prayer',
  },
  {
    id: 'INCENSE_BURNER_OF_TRANQUILITY_B',
    name: 'Incense burner of tranquility',
    quanlity: 'B',
    image: 'Incense burner of tranquility',
  },
  // {
  //   id: 'CRYSTAL_OF_OO_R',
  //   name: 'Crystal',
  //   quanlity: 'R',
  //   image: 'Premium Aroma',
  // },
  // {
  //   id: 'HP_COACH_R',
  //   name: 'HP COACH',
  //   quanlity: 'R',
  //   image: 'Premium Aroma',
  // },
  // {
  //   id: 'STR_COACH_R',
  //   name: 'STR COACH',
  //   quanlity: 'R',
  //   image: 'Premium Aroma',
  // },
  // {
  //   id: 'INT_COACH_R',
  //   name: 'INT COACH',
  //   quanlity: 'R',
  //   image: 'Premium Aroma',
  // },
  // {
  //   id: 'DEX_COACH_R',
  //   name: 'DEX COACH',
  //   quanlity: 'R',
  //   image: 'Premium Aroma',
  // },
  // {
  //   id: 'AGI_COACH_R',
  //   name: 'AGI COACH',
  //   quanlity: 'R',
  //   image: 'Premium Aroma',
  // },
  // {
  //   id: 'VIT_COACH_R',
  //   name: 'VIT COACH',
  //   quanlity: 'R',
  //   image: 'Premium Aroma',
  // },
]

const FusionItem = [
  {
    id: 'FAIRY_FEATHER_UC',
    name: 'Fairy Feather',
    quanlity: 'UC',
    image: 'Fairy Feather',
  },
  {
    id: 'GOLEM_CORE_UC',
    name: 'Golem Core',
    quanlity: 'UC',
    image: 'Golem Core',
  },
  {
    id: 'YUBA_UC',
    name: 'Yuba...?',
    quanlity: 'UC',
    image: 'Yuba',
  },
  {
    id: 'UNICORN_HORN_UC',
    name: 'Unicorn Horn',
    quanlity: 'UC',
    image: 'Unicorn Horn',
  },
  {
    id: 'RED_JEWEL_UC',
    name: 'Red Jewel',
    quanlity: 'UC',
    image: 'Red Jewel',
  },
  {
    id: 'CAT_WHISKER_UC',
    name: 'Cat Whisker',
    quanlity: 'UC',
    image: 'Cat Whisker',
  },
  {
    id: 'WITCHS_WAND_UC',
    name: "Witch's Wand",
    quanlity: 'UC',
    image: "Witch's Wand",
  },
  {
    id: 'IMP_TAIL_UC',
    name: 'Imp Tail',
    quanlity: 'UC',
    image: 'Imp Tail',
  },
  {
    id: 'ODEN_UC',
    name: 'ODEN',
    quanlity: 'UC',
    image: 'Oden',
  },
  {
    id: 'AQUATIC_EGG_UC',
    name: "Water Nymph's Egg",
    quanlity: 'UC',
    image: "Water Nymph's Egg",
  },
  {
    id: 'WAFUKU_UC',
    name: 'Wafuku',
    quanlity: 'UC',
    image: 'Wafuku',
  },
  {
    id: 'SCHOOL_UNIFORM_UC',
    name: 'School Uniform',
    quanlity: 'UC',
    image: 'School Uniform',
  },
  {
    id: 'MOFUMOFU_UC',
    name: 'MOFUMOFU',
    quanlity: 'UC',
    image: 'MOFUMOFU',
  },
  {
    id: 'MAAT_UC',
    name: 'MAAT',
    quanlity: 'UC',
    image: 'MAAT',
  },
  {
    id: 'INDESTRUCTIBLE_ICE_UC',
    name: 'Indestructible Icecream',
    quanlity: 'UC',
    image: 'Indestructible Ice',
  },
  {
    id: 'KAWAII_CANDY_UC',
    name: 'Kawaii Candy',
    quanlity: 'UC',
    image: 'Kawaii Candy',
  },
  {
    id: 'DETERMINATION_OF_JUSTICE_UC',
    name: 'Determination of justice',
    quanlity: 'UC',
    image: 'Determination of justice',
  },
  {
    id: 'KNOWLEDGE_OF_MALICE_UC',
    name: 'Knowledge of malice',
    quanlity: 'UC',
    image: 'Knowledge of malice',
  },
  {
    id: 'SAKE_BARREL_UC',
    name: 'Sake Barrel',
    quanlity: 'UC',
    image: 'Sake Barrel',
  },
  {
    id: 'MECHANICAL_BLADE_UC',
    name: 'Mechanical Blade',
    quanlity: 'UC',
    image: 'Mechanical Blade',
  },
  {
    id: 'BELLYBAND_UC',
    name: 'Bellyband',
    quanlity: 'UC',
    image: 'Bellyband',
  },
  {
    id: 'SECRET_DATA_C',
    name: 'Secret Data[〇〇〇]',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'SECRET_DATA_UC',
    name: 'Secret Data[〇〇〇]',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'SECRET_DATA_R',
    name: 'Secret Data[〇〇〇]',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'BOOST_CHIP_STRENGTH_C',
    name: 'Boost Chip [Strength]',
    quanlity: 'C',
    image: 'Boost Chip [Strength]',
  },
  {
    id: 'BOOST_CHIP_STRENGTH_UC',
    name: 'Boost Chip [Strength]',
    quanlity: 'UC',
    image: 'Boost Chip [Strength]',
  },
  {
    id: 'BOOST_CHIP_STRENGTH_R',
    name: 'Boost Chip [Strength]',
    quanlity: 'R',
    image: 'Boost Chip [Strength]',
  },
  {
    id: 'BOOST_CHIP_INSIGHT_C',
    name: 'Boost Chip [Insight]',
    quanlity: 'C',
    image: 'Boost Chip [Insight]',
  },
  {
    id: 'BOOST_CHIP_INSIGHT_UC',
    name: 'Boost Chip [Insight]',
    quanlity: 'UC',
    image: 'Boost Chip [Insight]',
  },
  {
    id: 'BOOST_CHIP_INSIGHT_R',
    name: 'Boost Chip [Insight]',
    quanlity: 'R',
    image: 'Boost Chip [Insight]',
  },
  {
    id: 'BOOST_CHIP_PRECISION_C',
    name: 'Boost Chip [Precision]',
    quanlity: 'C',
    image: 'Boost Chip [Precision]',
  },
  {
    id: 'BOOST_CHIP_PRECISION_UC',
    name: 'Boost Chip [Precision]',
    quanlity: 'UC',
    image: 'Boost Chip [Precision]',
  },
  {
    id: 'BOOST_CHIP_PRECISION_R',
    name: 'Boost Chip [Precision]',
    quanlity: 'R',
    image: 'Boost Chip [Precision]',
  },
  {
    id: 'BOOST_CHIP_SOLID_C',
    name: 'Boost Chip [Solid]',
    quanlity: 'C',
    image: 'HP Seed',
  },
  {
    id: 'BOOST_CHIP_SOLID_UC',
    name: 'Boost Chip [Solid]',
    quanlity: 'UC',
    image: 'Boost Chip [Solid]',
  },
  {
    id: 'BOOST_CHIP_SOLID_R',
    name: 'Boost Chip [Solid]',
    quanlity: 'R',
    image: 'Boost Chip [Solid]',
  },
  {
    id: 'SECRET_DATA_B',
    name: 'Secret Data[〇〇〇]',
    quanlity: 'B',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'BOOST_CHIP_B',
    name: 'BOOST_CHIP_B',
    quanlity: 'B',
    image: 'Boost Chip [Solid]',
  },
  {
    id: 'FAIRY_FEATHER_B',
    name: 'Fairy Feather',
    quanlity: 'B',
    image: 'Fairy Feather',
  },
  {
    id: 'GOLEM_CORE_B',
    name: 'Golem Core',
    quanlity: 'B',
    image: 'Golem Core',
  },
  {
    id: 'YUBA_B',
    name: 'Yuba...?',
    quanlity: 'B',
    image: 'Yuba',
  },
  {
    id: 'UNICORN_HORN_B',
    name: 'Unicorn Horn',
    quanlity: 'B',
    image: 'Unicorn Horn',
  },
  {
    id: 'RED_JEWEL_B',
    name: 'Red Jewel',
    quanlity: 'B',
    image: 'Red Jewel',
  },
  {
    id: 'CAT_WHISKER_B',
    name: 'Cat Whisker',
    quanlity: 'B',
    image: 'Cat Whisker',
  },
  {
    id: 'WITCHS_WAND_B',
    name: "Witch's Wand",
    quanlity: 'B',
    image: "Witch's Wand",
  },
  {
    id: 'IMP_TAIL_B',
    name: 'Imp Tail',
    quanlity: 'B',
    image: 'Imp Tail',
  },
  {
    id: 'ODEN_B',
    name: 'ODEN',
    quanlity: 'B',
    image: 'Oden',
  },
  {
    id: 'AQUATIC_EGG_B',
    name: "Water Nymph's Egg",
    quanlity: 'B',
    image: "Water Nymph's Egg",
  },
  {
    id: 'WAFUKU_B',
    name: 'Wafuku',
    quanlity: 'B',
    image: 'Wafuku',
  },
  {
    id: 'SCHOOL_UNIFORM_B ',
    name: 'School Uniform',
    quanlity: 'B',
    image: 'School Uniform',
  },
  {
    id: 'MOFUMOFU_B',
    name: 'MOFUMOFU',
    quanlity: 'B',
    image: 'MOFUMOFU',
  },
  {
    id: 'MAAT_B',
    name: 'MAAT',
    quanlity: 'B',
    image: 'MAAT',
  },
  {
    id: 'INDESTRUCTIBLE_ICE_B',
    name: 'Indestructible Icecream',
    quanlity: 'B',
    image: 'Indestructible Ice',
  },
  {
    id: 'KAWAII_CANDY_B',
    name: 'Kawaii Candy',
    quanlity: 'B',
    image: 'Kawaii Candy',
  },
  {
    id: 'DETERMINATION_OF_JUSTICE_B',
    name: 'Determination of justice',
    quanlity: 'B',
    image: 'Determination of justice',
  },
  {
    id: 'KNOWLEDGE_OF_MALICE_B',
    name: 'Knowledge of malice',
    quanlity: 'B',
    image: 'Knowledge of malice',
  },
  {
    id: 'SAKE_BARREL_B',
    name: 'Sake Barrel',
    quanlity: 'B',
    image: 'Sake Barrel',
  },
  {
    id: 'MECHANICAL_BLADE_B',
    name: 'Mechanical Blade',
    quanlity: 'B',
    image: 'Mechanical Blade',
  },
  {
    id: 'BELLYBAND_B',
    name: 'Bellyband',
    quanlity: 'B',
    image: 'Bellyband',
  },
  {
    id: 'ADAPTABILITY_C',
    name: 'Adaptability',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'AGILITY_C',
    name: 'Agility',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'ARMOR_PIERCING_C',
    name: 'Armor Piercing',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'COACHING_C',
    name: 'Coaching',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'CONCENTRATION_C',
    name: 'Concentration',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'CONTRARIAN_C',
    name: 'Contrarian',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'CONVERGING_MUSCLES_C',
    name: 'Converging Muscles',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'EARTH_MASTERY_C',
    name: 'Earth Mastery',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'EARTH_APTITUDE_C',
    name: 'Earth Aptitude',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'ELEMENTAL_BODY_C',
    name: 'Elemental Body',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'FLAME_APTITUDE_C',
    name: 'Flame Aptitude',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'FLAME_MASTERY_C',
    name: 'Flame Mastery',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'FLEXIBLE_BODY_C',
    name: 'Flexible Body',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'FOUNTAIN_OF_WISDOM_C',
    name: 'Fountain of Wisdom',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'GENIUS_C',
    name: 'Genius',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'HEALING_WAVE_C',
    name: 'Healing Wave',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'HEALTHY_BODY_C',
    name: 'Healthy Body',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'HYPER_REACTIVITY_C',
    name: 'Hyper Reactivity',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'INHERITANCE_C',
    name: 'Inheritance',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'INSIGHT_C',
    name: 'Insight',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'IRON_HEART_C',
    name: 'Iron Heart',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'LIGHTNING_C',
    name: 'Lightning',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'LONGEVITY_C',
    name: 'Longevity',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'SLASHING_APTITUDE_C',
    name: 'Slashing Aptitude',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'SLASHING_MASTERY_C',
    name: 'Slashing Mastery',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'SNIPER_C',
    name: 'Sniper',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'STRIKE_APTITUDE_C',
    name: 'Strike Aptitude',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'STRIKE_MASTERY_C',
    name: 'Strike Mastery',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'STRONG_HEART_C',
    name: 'Strong Heart',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'SUPPORT_MASTER_C',
    name: 'Support Master',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'SURVIVER_C',
    name: 'Surviver',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'TAKE_A_CHANCE_C',
    name: 'Take a Chance',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'TALENT_FOR_SLEEP_C',
    name: 'Talent for Sleep',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'THRUST_APTITUDE_C',
    name: 'Thrust Aptitude',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'THRUST_MASTERY_C',
    name: 'Thrust Mastery',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'TREASURE_HUNTER_C',
    name: 'Treasure Hunter',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'WATER_APTITUDE_C',
    name: 'Water Aptitude',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'WATER_MASTERY_C',
    name: 'Water Mastery',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'WIND_APTITUDE_C',
    name: 'Wind Aptitude',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'WIND_MASTERY_C',
    name: 'Wind Mastery',
    quanlity: 'C',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'ADAPTABILITY_R',
    name: 'Adaptability',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'AGILITY_R',
    name: 'Agility',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'ARMOR_PIERCING_R',
    name: 'Armor Piercing',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'COACHING_R',
    name: 'Coaching',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'CONCENTRATION_R',
    name: 'Concentration',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'CONTRARIAN_R',
    name: 'Contrarian',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'CONVERGING_MUSCLES_R',
    name: 'Converging Muscles',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'EARTH_MASTERY_R',
    name: 'Earth Mastery',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'EARTH_APTITUDE_R',
    name: 'Earth Aptitude',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'ELEMENTAL_BODY_R',
    name: 'Elemental Body',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'FLAME_APTITUDE_R',
    name: 'Flame Aptitude',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'FLAME_MASTERY_R',
    name: 'Flame Mastery',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'FLEXIBLE_BODY_R',
    name: 'Flexible Body',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'FOUNTAIN_OF_WISDOM_R',
    name: 'Fountain of Wisdom',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'GENIUS_R',
    name: 'Genius',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'HEALING_WAVE_R',
    name: 'Healing Wave',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'HEALTHY_BODY_R',
    name: 'Healthy Body',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'HYPER_REACTIVITY_R',
    name: 'Hyper Reactivity',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'INHERITANCE_R',
    name: 'Inheritance',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'INSIGHT_R',
    name: 'Insight',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'IRON_HEART_R',
    name: 'Iron Heart',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'LIGHTNING_R',
    name: 'Lightning',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'LONGEVITY_R',
    name: 'Longevity',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'SLASHING_APTITUDE_R',
    name: 'Slashing Aptitude',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'SLASHING_MASTERY_R',
    name: 'Slashing Mastery',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'SNIPER_R',
    name: 'Sniper',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'STRIKE_APTITUDE_R',
    name: 'Strike Aptitude',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'STRIKE_MASTERY_R',
    name: 'Strike Mastery',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'STRONG_HEART_R',
    name: 'Strong Heart',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'SUPPORT_MASTER_R',
    name: 'Support Master',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'SURVIVER_R',
    name: 'Surviver',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'TAKE_A_CHANCE_R',
    name: 'Take a Chance',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'TALENT_FOR_SLEEP_R',
    name: 'Talent for Sleep',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'THRUST_APTITUDE_R',
    name: 'Thrust Aptitude',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'THRUST_MASTERY_R',
    name: 'Thrust Mastery',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'TREASURE_HUNTER_R',
    name: 'Treasure Hunter',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'WATER_APTITUDE_R',
    name: 'Water Aptitude',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'WATER_MASTERY_R',
    name: 'Water Mastery',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'WIND_APTITUDE_R',
    name: 'Wind Aptitude',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'WIND_MASTERY_R',
    name: 'Wind Mastery',
    quanlity: 'R',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'ADAPTABILITY_UC',
    name: 'Adaptability',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'AGILITY_UC',
    name: 'Agility',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'ARMOR_PIERCING_UC',
    name: 'Armor Piercing',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'COACHING_UC',
    name: 'Coaching',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'CONCENTRATION_UC',
    name: 'Concentration',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'CONTRARIAN_UC',
    name: 'Contrarian',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'CONVERGING_MUSCLES_UC',
    name: 'Converging Muscles',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'EARTH_MASTERY_UC',
    name: 'Earth Mastery',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'EARTH_APTITUDE_UC',
    name: 'Earth Aptitude',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'ELEMENTAL_BODY_UC',
    name: 'Elemental Body',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'FLAME_APTITUDE_UC',
    name: 'Flame Aptitude',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'FLAME_MASTERY_UC',
    name: 'Flame Mastery',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'FLEXIBLE_BODY_UC',
    name: 'Flexible Body',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'FOUNTAIN_OF_WISDOM_UC',
    name: 'Fountain of Wisdom',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'GENIUS_UC',
    name: 'Genius',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'HEALING_WAVE_UC',
    name: 'Healing Wave',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'HEALTHY_BODY_UC',
    name: 'Healthy Body',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'HYPER_REACTIVITY_UC',
    name: 'Hyper Reactivity',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'INHERITANCE_UC',
    name: 'Inheritance',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'INSIGHT_UC',
    name: 'Insight',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'IRON_HEART_UC',
    name: 'Iron Heart',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'LIGHTNING_UC',
    name: 'Lightning',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'LONGEVITY_UC',
    name: 'Longevity',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'SLASHING_APTITUDE_UC',
    name: 'Slashing Aptitude',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'SLASHING_MASTERY_UC',
    name: 'Slashing Mastery',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'SNIPER_UC',
    name: 'Sniper',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'STRIKE_APTITUDE_UC',
    name: 'Strike Aptitude',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'STRIKE_MASTERY_UC',
    name: 'Strike Mastery',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'STRONG_HEART_UC',
    name: 'Strong Heart',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'SUPPORT_MASTER_UC',
    name: 'Support Master',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'SURVIVER_UC',
    name: 'Surviver',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'TAKE_A_CHANCE_UC',
    name: 'Take a Chance',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'TALENT_FOR_SLEEP_UC',
    name: 'Talent for Sleep',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'THRUST_APTITUDE_UC',
    name: 'Thrust Aptitude',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'THRUST_MASTERY_UC',
    name: 'Thrust Mastery',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'TREASURE_HUNTER_UC',
    name: 'Treasure Hunter',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'WATER_APTITUDE_UC',
    name: 'Water Aptitude',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'WATER_MASTERY_UC',
    name: 'Water Mastery',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'WIND_APTITUDE_UC',
    name: 'Wind Aptitude',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
  {
    id: 'WIND_MASTERY_UC',
    name: 'Wind Mastery',
    quanlity: 'UC',
    image: 'Secret Data[〇〇〇]',
  },
]

const RegenerationItem = [
  {
    id: 'REGENERATION_TICKET_R',
    name: 'REGENERATION_TICKET_R',
    quanlity: 'R',
    image: 'Exploration Ticket',
  },
  {
    id: 'REGENERATION_TICKET_B',
    name: 'REGENERATION_TICKET_B',
    quanlity: 'B',
    image: 'Exploration Ticket',
  },
  {
    id: 'REGENERATION_HASH_RANDOM_R',
    name: 'Regeneration Hash [Random]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_B',
    name: 'Regeneration Hash',
    quanlity: 'B',
    image: 'Hash Fragment',
  },
  {
    id: 'HASH_FRAGMENT_UC',
    name: 'Hash Fragment',
    quanlity: 'UC',
    image: 'Hash Fragment',
  },
  {
    id: 'HASH_FRAGMENT_R',
    name: 'Hash Fragment',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'HASH_FRAGMENT_B',
    name: 'Hash Fragment',
    quanlity: 'B',
    image: 'Hash Fragment',
  },

  {
    id: 'REGENERATION_HASH_FAIREAF_R',
    name: 'Regeneration Hash [Faireaf]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_WHISPER_R',
    name: 'Regeneration Hash [Whisper]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_WOLFAIR_R',
    name: 'Regeneration Hash [Wolfair]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_AETHER_R',
    name: 'Regeneration Hash [Aether]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_LILIM_R',
    name: 'Regeneration Hash [Lilim]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_CANTIS_R',
    name: 'Regeneration Hash [Cantis]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_CALIY_R',
    name: 'Regeneration Hash [Caliy]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_GOLEM_R',
    name: 'Regeneration Hash [Golem]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_LOOPER_R',
    name: 'Regeneration Hash [Looper]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_RABILEN_R',
    name: 'Regeneration Hash [Rabilen]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_PATROLEM_R',
    name: 'Regeneration Hash [Patrolem]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_PAZUZU_R',
    name: 'Regeneration Hash [Pazuzu]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_WOODEN_R',
    name: 'Regeneration Hash [Wooden]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_QUACKLE_R',
    name: 'Regeneration Hash [Quackle]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_KOYA_R',
    name: 'Regeneration Hash [Koya]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_TOFU_R',
    name: 'Regeneration Hash [Tofu]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_NERIYOKAN_R',
    name: 'Regeneration Hash [NeriYokan]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_TIMEKEEPER_R',
    name: 'Regeneration Hash [Timekeeper]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_SAINOME_R',
    name: 'Regeneration Hash [Sainome]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_BLUE_MINT_R',
    name: 'Regeneration Hash [Blue-mint]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_YAKI_TOFU_R',
    name: 'Regeneration Hash [Yaki-tofu]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_UNICORN_R',
    name: 'Regeneration Hash [Unicorn]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_CRIMSON_R',
    name: 'Regeneration Hash [Crimson]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_SKYWALKER_R',
    name: 'Regeneration Hash [Skywalker]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_SOUNDHORSE_R',
    name: 'Regeneration Hash [Soundhorse]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_KELPIE_R',
    name: 'Regeneration Hash [Kelpie]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_BURHIMU_R',
    name: 'Regeneration Hash [Burhimu]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_HANAMIUMA_R',
    name: 'Regeneration Hash [Hanamiuma]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_WOLHORN_R',
    name: 'Regeneration Hash [Wolhorn]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_VALIUS_R',
    name: 'Regeneration Hash [Valius]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_STARGAZER_R',
    name: 'Regeneration Hash [Stargazer]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_BRAZEHOPPER_R',
    name: 'Regeneration Hash [BrazeHopper]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_VALY_R',
    name: 'Regeneration Hash [Valy]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_NIGHTTERROR_R',
    name: 'Regeneration Hash [NightTerror]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_SKYFANG_R',
    name: 'Regeneration Hash [SkyFang]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_FAIRYCATS_R',
    name: 'Regeneration Hash [FairyCats]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_TOFUCATS_R',
    name: 'Regeneration Hash [TofuCats]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_ASTARCATS_R',
    name: 'Regeneration Hash [AstarCats]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_WITCHCATS_R',
    name: 'Regeneration Hash [WitchCats]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_WAFUKUCATS_R',
    name: 'Regeneration Hash [WafukuCats]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_VILLAINCATS_R',
    name: 'Regeneration Hash [VillainCats]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_MASKCATS_R',
    name: 'Regeneration Hash [MaskCats]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_ARIES_R',
    name: 'Regeneration Hash [Aries]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_TAURUS_R',
    name: 'Regeneration Hash [Taurus]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_GEM_R',
    name: 'Regeneration Hash [Gem]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_MEG_R',
    name: 'Regeneration Hash [Meg]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_CANCER_R',
    name: 'Regeneration Hash [Cancer]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_LEO_R',
    name: 'Regeneration Hash [Leo]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_VIRGILIA_R',
    name: 'Regeneration Hash [Virgilia]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_LIBRA_R',
    name: 'Regeneration Hash [Libra]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_SCORPIUS_R',
    name: 'Regeneration Hash [Scorpius]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_SAGITTARIUS_R',
    name: 'Regeneration Hash [Sagittarius]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_CAPRICORN_R',
    name: 'Regeneration Hash [Capricorn]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_AQUARIUS_R',
    name: 'Regeneration Hash [Aquarius]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_PISCES_R',
    name: 'Regeneration Hash [Pisces]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_BELLA_R',
    name: 'Regeneration Hash [Bella]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_LUNA_R',
    name: 'Regeneration Hash [Luna]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_OLIVIA_R',
    name: 'Regeneration Hash [Olivia]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_LAYLA_R',
    name: 'Regeneration Hash [Layla]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_MATOI_R',
    name: 'Regeneration Hash [Matoi]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_SORA_R',
    name: 'Regeneration Hash [Sora]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_EVE_R',
    name: 'Regeneration Hash [Eve]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_POCHI_R',
    name: 'Regeneration Hash [Pochi]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_LABY_R',
    name: 'Regeneration Hash [Laby]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_NYANPEN_R',
    name: 'Regeneration Hash [Nyanpen]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_KUMATAMA_R',
    name: 'Regeneration Hash [Kumatama]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_MONTA_R',
    name: 'Regeneration Hash [Monta]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_UPA_GOREM_R',
    name: 'Regeneration Hash [UPA_Gorem]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_UPA_AC_R',
    name: 'Regeneration Hash [UPA_AC]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_UPA_ODEN_R',
    name: 'Regeneration Hash [UPA_ODEN]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_UPA_R',
    name: 'Regeneration Hash [UPA]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_UPA_1912_R',
    name: 'Regeneration Hash [UPA_1912]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_UPA_VILLAIN_R',
    name: 'Regeneration Hash [UPA_Villain]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_UPA_OSSAN_R',
    name: 'Regeneration Hash [UPA_Ossan]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_MEI_R',
    name: 'Regeneration Hash [Mei]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_ORIKA_R',
    name: 'Regeneration Hash [Orika]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_AKANE_R',
    name: 'Regeneration Hash [Akane]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_AYANO_R',
    name: 'Regeneration Hash [Ayano]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_HIYORI_R',
    name: 'Regeneration Hash [Hiyori]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_NANAMI_R',
    name: 'Regeneration Hash [Nanami]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_YUI_R',
    name: 'Regeneration Hash [Yui]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_YAMATO_WHITE_R',
    name: 'Regeneration Hash [Yamato_White]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_YAMATO_T_SHIRT_R',
    name: 'Regeneration Hash [YamatoT_Shirt]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_YAMATO_WAITER_R',
    name: 'Regeneration Hash [Yamato_Waiter]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_YAMATO_UPA_R',
    name: 'Regeneration Hash [Yamato_UPA]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_YAMATO_R',
    name: 'Regeneration Hash [Yamato]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_YAMATO_DRESS_R',
    name: 'Regeneration Hash [Yamato_Dress]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_YAMATO_X_R',
    name: 'Regeneration Hash [Yamato_X]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_GOLIATH_R',
    name: 'Regeneration Hash [GOLIATH]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_SOY_R',
    name: 'Regeneration Hash [SOY]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_DEKALABY_R',
    name: 'Regeneration Hash [DEKALABY]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_BANCHO_R',
    name: 'Regeneration Hash [BANCHO]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_DEKAUSA_R',
    name: 'Regeneration Hash [DEKAUSA]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_DEKACONI_R',
    name: 'Regeneration Hash [DEKACONI]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_TARUUSA_R',
    name: 'Regeneration Hash [TARUUSA]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_CONI_R',
    name: 'Regeneration Hash [Coni]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_MOUS_R',
    name: 'Regeneration Hash [Mous]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_BOT_R',
    name: 'Regeneration Hash [Bot]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_CONIC_R',
    name: 'Regeneration Hash [Conic]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_VANILLA_R',
    name: 'Regeneration Hash [Vanilla]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_EMERALD_SURFER_R',
    name: 'Regeneration Hash [EmeraldSufer]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_OGAKI_BLUE_R',
    name: 'Regeneration Hash [OgakiBlue]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_BUNNY_BUNNY_R',
    name: 'Regeneration Hash [BunnyBunny]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_CARAMEL_FROMAGE_R',
    name: 'Regeneration Hash [CaramelFromage]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_ICELIME_R',
    name: 'Regeneration Hash [Icelime]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_STAR_BERRY_R',
    name: 'Regeneration Hash [StarBerry]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_AME_CHAN_POWERED_R',
    name: 'Regeneration Hash [AmeChanPowered]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_AME_CHAN_IMP_R',
    name: 'Regeneration Hash [AmeChanImp]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_AME_CHAN_WAFUKU_R',
    name: 'Regeneration Hash [AmeChanWafuku]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_AME_CHAN_SCHOOL_R',
    name: 'Regeneration Hash [AmeChanSchool]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_AME_CHAN_SUMMER_R',
    name: 'Regeneration Hash [AmeChanSummer]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_AME_CHAN_R',
    name: 'Regeneration Hash [AmeChan]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_AME_CHAN_VILLAIN_R',
    name: 'Regeneration Hash [AmeChanVillain]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_KIDSHERO1_R',
    name: 'Regeneration Hash [KidsHero1]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_KIDSHERO2_R',
    name: 'Regeneration Hash [KidsHero2]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_KIDSHERO3_R',
    name: 'Regeneration Hash [KidsHero3]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_KIDSHERO4_R',
    name: 'Regeneration Hash [KidsHero4]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_KIDSHERO5_R',
    name: 'Regeneration Hash [KidsHero5]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_KIDSVILLAIN1_R',
    name: 'Regeneration Hash [KidsVillain1]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_KIDSVILLAIN2_R',
    name: 'Regeneration Hash [KidsVillain2]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_KIDSVILLAIN3_R',
    name: 'Regeneration Hash [KidsVillain3]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_KIDSVILLAIN4_R',
    name: 'Regeneration Hash [KidsVillain4]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_KIDSVILLAIN5_R',
    name: 'Regeneration Hash [KidsVillain5]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_SAKE_METAL_PLANET_MAN_R',
    name: 'Regeneration Hash [SakeMetalPlanetMan]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_SAKE_SHIRAKI_PLANET_MAN_R',
    name: 'Regeneration Hash [SakeShirakiPlanetMan]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_MATATABI_BARREL_PLANET_MAN_R',
    name: 'Regeneration Hash [MatatabiBarrelPlanetMan]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_SAKE_POTTERY_PLANET_MAN_R',
    name: 'Regeneration Hash [SakePotteryPlanetMan]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_KIDS_BARREL_PLANET_MAN_R',
    name: 'Regeneration Hash [KidsBarrelPlanetMan]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_SAKE_BURREL_PLANET_MAN_R',
    name: 'Regeneration Hash [SakeBurrelPlanetMan]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_SAKE_CUP_PLANET_MAN_R',
    name: 'Regeneration Hash [SakeCupPlanetMan]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_F2_R',
    name: 'Regeneration Hash [F2]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_W3_R',
    name: 'Regeneration Hash [W3]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_G20_R',
    name: 'Regeneration Hash [G20]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_J8_R',
    name: 'Regeneration Hash [J8]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_I12_R',
    name: 'Regeneration Hash [I12]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_H17_R',
    name: 'Regeneration Hash [H17]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_P1_R',
    name: 'Regeneration Hash [P1]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_OSSAN_PARADISE1_R',
    name: 'Regeneration Hash [OssanParadise1]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_OSSAN_PARADISE2_R',
    name: 'Regeneration Hash [OssanParadise2]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_OSSAN_PARADISE3_R',
    name: 'Regeneration Hash [OssanParadise3]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_OSSAN_PARADISE4_R',
    name: 'Regeneration Hash [OssanParadise4]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
  {
    id: 'REGENERATION_HASH_OSSAN_PARADISE5_R',
    name: 'Regeneration Hash [OssanParadise5]',
    quanlity: 'R',
    image: 'Hash Fragment',
  },
]

var remainingTrainingItem = []
var remainingEnhanceItem = []
var remainingFusionItem = []
var remainingRegenerationItem = []

export function getNameItem(type, id) {
  switch (Number(type)) {
    case 0:
      return TraningItem[Number(id)]
    case 1:
      return EnhanceItem[Number(id)]
    case 2:
      return FusionItem[Number(id)]
    case 3:
      return RegenerationItem[Number(id)]
    default:
      return ''
  }
}

export async function getBalanceItem(addressContract, addressUser, id) {
  switch (addressContract?.toLowerCase()) {
    case configs.ADDRESS_TRAINING_ITEM.toLowerCase():
      return await ContractTrainingItem.balanceOf(addressUser, id)
    case configs.ADDRESS_ENHANCE_ITEM.toLowerCase():
      return await ContractEnhanceItem.balanceOf(addressUser, id)
    case configs.ADDRESS_FUSION_ITEM.toLowerCase():
      return await ContractFusionItem.balanceOf(addressUser, id)
    case configs.ADDRESS_REGENERATION_ITEM.toLowerCase():
      return await ContractRegenerationItem.balanceOf(addressUser, id)
    default:
      return 0
  }
}

export function getNameItemByContract(address, id) {
  switch (address?.toLowerCase()) {
    case configs.ADDRESS_TRAINING_ITEM.toLowerCase():
      return TraningItem[Number(id)]
    case configs.ADDRESS_ENHANCE_ITEM.toLowerCase():
      return EnhanceItem[Number(id)]
    case configs.ADDRESS_FUSION_ITEM.toLowerCase():
      return FusionItem[Number(id)]
    case configs.ADDRESS_REGENERATION_ITEM.toLowerCase():
      return RegenerationItem[Number(id)]
    default:
      return ''
  }
}

export function getQuantity(itemList, remaining, id) {
  var itemId = itemList.findIndex((item) => item.id === id)
  var rm = remaining[itemId]
  rm = rm != -1 ? rm : 'Unlimited'
  return rm?.toLocaleString('en-US')
}

export async function getItemQuantity() {
  var trainingItemIds = [...Array(TraningItem.length).keys()]
  remainingTrainingItem = await ContractTrainingItem.getDetaiItems(
    trainingItemIds,
  )
  remainingTrainingItem = remainingTrainingItem.map((element) => {
    if (element.unlimited || element.amountLimit == 0) {
      return -1
    } else {
      return element.amountLimit - element.totalAmount
    }
  })

  var enhanceItemIds = [...Array(EnhanceItem.length).keys()]
  remainingEnhanceItem = await ContractEnhanceItem.getDetaiItems(enhanceItemIds)
  remainingEnhanceItem = remainingEnhanceItem.map((element) => {
    if (element.unlimited || element.amountLimit == 0) {
      return -1
    } else {
      return element.amountLimit - element.totalAmount
    }
  })

  var fusionItemIds = [...Array(FusionItem.length).keys()]
  remainingFusionItem = await ContractFusionItem.getDetaiItems(fusionItemIds)
  remainingFusionItem = remainingFusionItem.map((element) => {
    if (element.unlimited || element.amountLimit == 0) {
      return -1
    } else {
      return element.amountLimit - element.totalAmount
    }
  })

  const regenerationItemIds = [
    6,
    7,
    2,
    5,
    0,
    3,
    4, // match with item on blockchain smart contract
    ...Array.from({ length: 142 }, (_, i) => i + 10000),
  ]
  remainingRegenerationItem = await ContractRegenerationItem.getDetaiItems(
    regenerationItemIds,
  )
  remainingRegenerationItem = remainingRegenerationItem.map((element) => {
    if (element.unlimited && element.amountLimit == 0) {
      return -1
    } else {
      return element.amountLimit - element.totalAmount
    }
  })
}

export function getNameItemV2(type) {
  const listItems = TraningItem?.concat(
    EnhanceItem,
    FusionItem,
    RegenerationItem,
  )

  const items = listItems?.find((item) => item?.id === type)
  return items?.image === '' ? 'Energy_banana' : items?.image
}

export const ALL_ITEMS = TraningItem?.concat(
  EnhanceItem,
  FusionItem,
  RegenerationItem,
)

export const ALL_ITEMS_SELECT = ALL_ITEMS?.map((item) => ({
  label: (
    <div className="flex">
      <div className="mr-2 flex items-center object-contain">
        <img
          width={24}
          height={24}
          src={`${
            process.env.REACT_APP_BASE_URL_IMAGE
          }/public/image/image/items/${getNameItemV3(item?.id)?.image}.png`}
          alt=""
        />
      </div>
      <div>
        {item?.name} {item?.quanlity}
      </div>
    </div>
  ),
  value: item?.id,
}))

export const getListOptionItem = (collection) => {
  getItemQuantity()
  const generateItemList = (itemList, remaining) =>
    itemList?.map((item) => ({
      key: item?.id,
      label: (
        <Row>
          <Col span={17}>
            <div className="flex">
              <div className="mr-2 flex items-center object-contain">
                <img
                  width={24}
                  height={24}
                  src={`${
                    process.env.REACT_APP_BASE_URL_IMAGE
                  }/public/image/image/items/${
                    getNameItemV3(item?.id)?.image
                  }.png`}
                  alt=""
                />
              </div>
              <div className="flex">
                {item?.name}
                <img
                  width={20}
                  height={20}
                  src={`${process.env.PUBLIC_URL}/svgs/rankItem/${item?.quanlity}.svg`}
                  alt=""
                  style={{ marginLeft: '8px' }}
                />
              </div>
            </div>
          </Col>

          <Col span={7}>
            Remaining: {getQuantity(itemList, remaining, item?.id)}
          </Col>
        </Row>
      ),
      value: item?.id,
      name: item?.name,
      quantity: getQuantity(itemList, remaining, item?.id),
    }))
  switch (collection) {
    case 'Training_Item':
      return generateItemList(TraningItem, remainingTrainingItem)
    case 'Enhance_Item':
      return generateItemList(EnhanceItem, remainingEnhanceItem)
    case 'Fusion_Item':
      return generateItemList(FusionItem, remainingFusionItem)
    default:
      return generateItemList(RegenerationItem, remainingRegenerationItem)
  }
}

export function getNameItemV3(type) {
  const listItems = TraningItem?.concat(
    EnhanceItem,
    FusionItem,
    RegenerationItem,
    // ShopItem,
  )

  return listItems?.find((item) => item?.id === type)
}
