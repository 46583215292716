// export function installMetamask() {
//   window.open("https://metamask.app.link/dapp/" + PATH_METAMASK, "_blank");
// }
export async function changeNetworkInMetamask(
  provider,
  chainId,
  setLoading = null,
) {
  var params
  switch (chainId) {
    case 29548:
      params = [
        {
          chainId: '0x736C',
          chainName: 'MCH Verse',
          nativeCurrency: {
            symbol: 'OAS',
            decimals: 18,
          },
          rpcUrls: ['https://rpc.oasys.mycryptoheroes.net/'],
          blockExplorerUrls: ['https://explorer.oasys.mycryptoheroes.net/'],
        },
      ]
      break
    case 20197:
      params = [
        {
          chainId: '0x4EE5',
          chainName: 'Sand Verse - Testnet',
          nativeCurrency: {
            symbol: 'OAS',
            decimals: 18,
          },
          rpcUrls: ['https://rpc.sandverse.oasys.games/'],
          blockExplorerUrls: ['https://explorer.sandverse.oasys.games/'],
        },
      ]
      break
    default:
      params = [
        {
          chainId: '0x4EE5',
          chainName: 'Sand Verse - Testnet',
          nativeCurrency: {
            symbol: 'OAS',
            decimals: 18,
          },
          rpcUrls: ['https://rpc.sandverse.oasys.games/'],
          blockExplorerUrls: ['https://explorer.sandverse.oasys.games/'],
        },
      ]
      break
  }
  try {
    switch (chainId) {
      case 29548:
        // check if the chain to connect to is installed
        return await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x736C' }], // chainId must be in hexadecimal numbers
        })
      case 20197:
        // check if the chain to connect to is installed
        return await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x4EE5' }], // chainId must be in hexadecimal numbers
        })
      default:
        // check if the chain to connect to is installed
        return await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x4EE5' }], // chainId must be in hexadecimal numbers
        })
    }
  } catch (switchError) {
    if (switchError.code === 4902 || switchError.code === -32603) {
      await provider
        .request({
          method: 'wallet_addEthereumChain',
          params: params,
        })
        .then((res) => {
          return res
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(addError)
        })
    } else {
      if (setLoading) {
        setLoading(false)
      }
    }
  }
}
