export const GET_LIST_POOL_START = 'GET_LIST_POOL_START'
export const GET_LIST_POOL_SUCCESS = 'GET_LIST_POOL_SUCCESS'
export const GET_LIST_POOL_FAILED = 'GET_LIST_POOL_FAILED'

export const CREATE_POOL_START = 'CREATE_POOL_START'
export const CREATE_POOL_SUCCESS = 'CREATE_POOL_SUCCESS'
export const CREATE_POOL_FAILED = 'CREATE_POOL_FAILED'

export const EDIT_POOL_START = 'EDIT_POOL_START'
export const EDIT_POOL_SUCCESS = 'EDIT_POOL_SUCCESS'
export const EDIT_POOL_FAILED = 'EDIT_POOL_FAILED'

export const CLOSE_POOL_START = 'CLOSE_POOL_START'
export const CLOSE_POOL_SUCCESS = 'CLOSE_POOL_SUCCESS'
export const CLOSE_POOL_FAILED = 'CLOSE_POOL_FAILED'

export const GET_DEPOSIT_POOLS_START = 'GET_DEPOSIT_POOLS_START'
export const GET_DEPOSIT_POOLS_SUCCESS = 'GET_DEPOSIT_POOLS_SUCCESS'
export const GET_DEPOSIT_POOLS_FAILED = 'GET_DEPOSIT_POOLS_FAILED'

export const GET_WITHDRAW_POOLS_START = 'GET_WITHDRAW_POOLS_START'
export const GET_WITHDRAW_POOLS_SUCCESS = 'GET_WITHDRAW_POOLS_SUCCESS'
export const GET_WITHDRAW_POOLS_FAILED = 'GET_WITHDRAW_POOLS_FAILED'

export const UPDATE_DEPOSIT_POOLS_START = 'UPDATE_DEPOSIT_POOLS_START'
export const UPDATE_DEPOSIT_POOLS_SUCCESS = 'UPDATE_DEPOSIT_POOLS_SUCCESS'
export const UPDATE_DEPOSIT_POOLS_FAILED = 'UPDATE_DEPOSIT_POOLS_FAILED'

export const UPDATE_WITHDRAW_POOLS_START = 'UPDATE_WITHDRAW_POOLS_START'
export const UPDATE_WITHDRAW_POOLS_SUCCESS = 'UPDATE_WITHDRAW_POOLS_SUCCESS'
export const UPDATE_WITHDRAW_POOLS_FAILED = 'UPDATE_WITHDRAW_POOLS_FAILED'
//-------------------------Pools------------------------//
/**
 * [1]
 * get deposit pools start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getDepositPools(payload, onSuccess, onError) {
  return {
    type: GET_DEPOSIT_POOLS_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get deposit pools success action
 * @param {*} payload
 * @returns {object}
 */
export function getDepositPoolsSuccess(payload) {
  return {
    type: GET_DEPOSIT_POOLS_SUCCESS,
    payload: payload,
  }
}

/**
 * get deposit pools failed action
 * @returns {object}
 */
export function getDepositPoolsFailed() {
  return {
    type: GET_DEPOSIT_POOLS_FAILED,
  }
}

/**
 * [2]
 * get withdraw pools start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getWithdrawPools(payload, onSuccess, onError) {
  return {
    type: GET_WITHDRAW_POOLS_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get withdraw pools success action
 * @param {*} payload
 * @returns {object}
 */
export function getWithdrawPoolsSuccess(payload) {
  return {
    type: GET_WITHDRAW_POOLS_SUCCESS,
    payload: payload,
  }
}

/**
 * get withdraw pools failed action
 * @returns {object}
 */
export function getWithdrawPoolsFailed() {
  return {
    type: GET_WITHDRAW_POOLS_FAILED,
  }
}

/**
 * [3]
 * update deposit pools start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updateDepositPools(payload, onSuccess, onError) {
  return {
    type: UPDATE_DEPOSIT_POOLS_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * update deposit pools success action
 * @param {*} payload
 * @returns {object}
 */
export function updateDepositPoolsSuccess(payload) {
  return {
    type: UPDATE_DEPOSIT_POOLS_SUCCESS,
    payload: payload,
  }
}

/**
 * update deposit pools failed action
 * @returns {object}
 */
export function updateDepositPoolsFailed() {
  return {
    type: UPDATE_DEPOSIT_POOLS_FAILED,
  }
}

/**
 * [4]
 * update withdraw pools start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function updateWithdrawPools(payload, onSuccess, onError) {
  return {
    type: UPDATE_WITHDRAW_POOLS_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * update withdraw pools success action
 * @param {*} payload
 * @returns {object}
 */
export function updateWithdrawPoolsSuccess(payload) {
  return {
    type: UPDATE_WITHDRAW_POOLS_SUCCESS,
    payload: payload,
  }
}

/**
 * update withdraw pools failed action
 * @returns {object}
 */
export function updateWithdrawPoolsFailed() {
  return {
    type: UPDATE_WITHDRAW_POOLS_FAILED,
  }
}

/**
 * [5]
 * get list pools start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getListPools(payload, onSuccess, onError) {
  return {
    type: GET_LIST_POOL_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get list pools success action
 * @param {*} payload
 * @returns {object}
 */
export function getListPoolsSuccess(payload) {
  return {
    type: GET_LIST_POOL_SUCCESS,
    payload: payload,
  }
}

/**
 * get list pools failed action
 * @returns {object}
 */
export function getListPoolsFailed() {
  return {
    type: GET_LIST_POOL_FAILED,
  }
}

/**
 * [6]
 * Create pools start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function createPool(payload, onSuccess, onError) {
  return {
    type: CREATE_POOL_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * Create pools success action
 * @param {*} payload
 * @returns {object}
 */
export function createPoolSuccess(payload) {
  return {
    type: CREATE_POOL_SUCCESS,
    payload: payload,
  }
}

/**
 * Create pool failed action
 * @returns {object}
 */
export function createPoolFailed() {
  return {
    type: CREATE_POOL_FAILED,
  }
}

/**
 * [7]
 * Edit pools start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function editPool(payload, onSuccess, onError) {
  return {
    type: EDIT_POOL_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * Edit pools success action
 * @param {*} payload
 * @returns {object}
 */
export function editPoolSuccess(payload) {
  return {
    type: EDIT_POOL_SUCCESS,
    payload: payload,
  }
}

/**
 * Edit pool failed action
 * @returns {object}
 */
export function editPoolFailed() {
  return {
    type: EDIT_POOL_FAILED,
  }
}

/**
 * [8]
 * Close pools start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function closePool(payload, onSuccess, onError) {
  return {
    type: CLOSE_POOL_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * Close pools success action
 * @param {*} payload
 * @returns {object}
 */
export function closePoolSuccess(payload) {
  return {
    type: CLOSE_POOL_SUCCESS,
    payload: payload,
  }
}

/**
 * Close pool failed action
 * @returns {object}
 */
export function closePoolFailed() {
  return {
    type: CLOSE_POOL_FAILED,
  }
}

export default {
  getDepositPools,
  getDepositPoolsSuccess,
  getDepositPoolsFailed,

  getWithdrawPools,
  getWithdrawPoolsSuccess,
  getWithdrawPoolsFailed,

  updateDepositPools,
  updateDepositPoolsSuccess,
  updateDepositPoolsFailed,

  updateWithdrawPools,
  updateWithdrawPoolsSuccess,
  updateWithdrawPoolsFailed,

  getListPools,
  getListPoolsSuccess,
  getListPoolsFailed,

  createPool,
  createPoolSuccess,
  createPoolFailed,

  editPool,
  editPoolSuccess,
  editPoolFailed,

  closePool,
  closePoolSuccess,
  closePoolFailed
}
