import PropTypes from 'prop-types'
import { useTranslation } from '~/common/hooks/useTranslation'
import {
  Form,
  Modal,
  Input,
  Button,
  notification,
  Row,
  Col,
  DatePicker,
} from 'antd'
import React, { useEffect, useState } from 'react'
import {
  NOTIFICATION_DURATION,
  cancelButtonStyle,
  okButtonStyle,
} from '~/common/constants'
import moment from 'moment'
import { useSDK } from '@metamask/sdk-react'
import { changeNetworkInMetamask } from '~/blockchain/metamask'
import configs from '~/configs'
import { useAccount } from '~/common/hooks/useAccount'
import { checkTransactionConfirmation, hexToNumberString, numberToHex, toWei } from '~/blockchain/provider'
import { ifaceContractPool } from '~/blockchain/contract'
function DepositForm(props) {
  const { t } = useTranslation()
  const { sdk } = useSDK()
  const { id, isShowModal, onClose, ranking } = props
  const [isLoading, setIsloading] = useState(false);
  const [form] = Form.useForm()
  const { user } = useAccount()
  const rules = {
    amount: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementPools.rankingRewardsPool.form.amountDeposit'),
        }),
      },
    ],
  }

  const handleSubmit = async (values) => {
    try {
      setIsloading(true)
      await sdk.connect()
      await changeNetworkInMetamask(
        sdk.getProvider(),
        configs.NETWORK.chainId,
        setIsloading,
      )
      
      await sdk.connect()
      let networkId = await sdk
        .getProvider()
        .request({ method: 'eth_chainId', params: [] })
      networkId = hexToNumberString(networkId)

      if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return

      let nonce = await sdk.getProvider().request({
        method: 'eth_getTransactionCount',
        params: [user.address_wallet, 'latest'],
      })

      sdk
      .getProvider()
      .request({
        method: 'eth_sendTransaction',
        params: [
          {
            from: user.address_wallet,
            data: ifaceContractPool.encodeFunctionData('deposit', [
              toWei(values?.amount),
            ]),
            to: configs.ADDRESS_POOL,
            value: numberToHex(toWei(values?.amount)),
            nonce,
          },
        ],
      })
      .then((txHash) => {
        checkTransactionConfirmation(txHash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, txHash)
            setIsloading(false)
            onSuccess()
          }
        })
      })
      .catch((error) => {
        setIsloading(false)
        // eslint-disable-next-line
        console.error(error)
      })
    } catch (error) {
      setIsloading(false)
    }
  }

  const onSuccess = () => {
    notification.success({
      message: t('common.message.depositSuccessfully'),
      duration: NOTIFICATION_DURATION,
    })
    onClose()
  }

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  return (
    <Modal
      title={"Deposit"}
      onCancel={handleClose}
      open={isShowModal}
      width={600}
      centered
      destroyOnClose
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        form={form}
        translate="yes"
        autoComplete="off"
        size="middle"
        validateTrigger={['onBlur', 'onChange']}
      >
        <Form.Item
          label={'Amount (OAS)'}
          name="amount"
          rules={rules.amount}
          labelAlign="left"
        >
          <Input
            placeholder={'Amount'}
          />
        </Form.Item>
        <div key="buttons" className="flex justify-center">
          <Button key="cancel" onClick={handleClose} style={cancelButtonStyle}>
            {t('common.btn.cancel')}
          </Button>
          <Button
            className="border-hidden"
            key="ok"
            htmlType="submit"
            type="primary"
            style={okButtonStyle}
            disabled={isLoading}
            loading={isLoading}
          >
            Deposit
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default DepositForm
