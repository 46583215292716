import { all } from 'redux-saga/effects'
//Auth
import watchLogin from './auth/login'
import watchLogout from './auth/logout'
import watchGetNonce from './auth/get-nonce'
import watchLoginGame from './auth/loginGame'
import watchLogoutGame from './auth/logoutgame'

/**
 * Root saga
 */

export default function* sagas() {
  yield all([
    //Auth
    watchLogin(),
    watchLogout(),
    watchGetNonce(),
    watchLoginGame(),
    watchLogoutGame(),
  ])
}
