export const LOGIN_START = 'LOGIN_START'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'

export const LOGOUT_START = 'LOGOUT_START'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILED = 'LOGOUT_FAILED'

export const LOGOUT_GAME_START = 'LOGOUT_GAME_START'
export const LOGOUT_GAME_SUCCESS = 'LOGOUT_GAME_SUCCESS'
export const LOGOUT_GAME_FAILED = 'LOGOUT_GAME_FAILED'

export const GET_NONCE_START = 'GET_NONCE_START'
export const GET_NONCE_SUCCESS = 'GET_NONCE_SUCCESS'
export const GET_NONCE_FAILED = 'GET_NONCE_FAILED'

export const LOGIN_GAME_START = 'LOGIN_GAME_START'
export const LOGIN_GAME_SUCCESS = 'LOGIN_GAME_SUCCESS'
export const LOGIN_GAME_FAILED = 'LOGIN_GAME_FAILED'

/**
 * Login
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function login(payload, onSuccess, onError) {
  return {
    type: LOGIN_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * Login success action
 * @param {*} payload
 * @returns {object}
 */
export function loginSuccess(payload) {
  return {
    type: LOGIN_SUCCESS,
    payload: payload,
  }
}

/**
 * Login failed action
 * @returns {object}
 */
export function loginFailed() {
  return {
    type: LOGIN_FAILED,
  }
}

/**
 * Logout
 * @param {any} callbackUrl
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function logout(callbackUrl, onSuccess, onError) {
  return {
    type: LOGOUT_START,
    callbackUrl: callbackUrl,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * Logout success action
 * @param {*} payload
 * @returns {object}
 */
export function logoutSuccess(payload) {
  return {
    type: LOGOUT_SUCCESS,
    payload: payload,
  }
}

/**
 * Logout failed action
 * @returns {object}
 */
export function logoutFailed() {
  return {
    type: LOGOUT_FAILED,
  }
}

/**
 * getNonce
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getNonce(payload, onSuccess, onError) {
  return {
    type: GET_NONCE_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * getNonce success action
 * @param {*} payload
 * @returns {object}
 */
export function getNonceSuccess(payload) {
  return {
    type: GET_NONCE_SUCCESS,
    payload: payload,
  }
}

/**
 * getNonce failed action
 * @returns {object}
 */
export function getNonceFailed() {
  return {
    type: GET_NONCE_FAILED,
  }
}

/**
 * Login game
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function loginGame(payload, onSuccess, onError) {
  return {
    type: LOGIN_GAME_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * Login success action
 * @param {*} payload
 * @returns {object}
 */
export function loginGameSuccess(payload) {
  return {
    type: LOGIN_GAME_SUCCESS,
    payload: payload,
  }
}

/**
 * Login failed action
 * @returns {object}
 */
export function loginGameFailed() {
  return {
    type: LOGIN_GAME_FAILED,
  }
}

/**
 * Logout game
 * @param {any} callbackUrl
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function logoutGame(callbackUrl, onSuccess, onError) {
  return {
    type: LOGOUT_GAME_START,
    callbackUrl: callbackUrl,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * Logout success action
 * @param {*} payload
 * @returns {object}
 */
export function logoutGameSuccess(payload) {
  return {
    type: LOGOUT_GAME_SUCCESS,
    payload: payload,
  }
}

/**
 * Logout failed action
 * @returns {object}
 */
export function logoutGameFailed() {
  return {
    type: LOGOUT_GAME_FAILED,
  }
}

export default {
  login,
  loginSuccess,
  loginFailed,

  loginGame,
  loginGameSuccess,
  loginGameFailed,

  logout,
  logoutSuccess,
  logoutFailed,

  logoutGame,
  logoutGameSuccess,
  logoutGameFailed,

  getNonce,
  getNonceSuccess,
  getNonceFailed,
}
