import {
  GET_LIST_TOURNAMENT_START,
  GET_LIST_TOURNAMENT_SUCCESS,
  GET_LIST_TOURNAMENT_FAILED,
} from '../actions/sponsorTournament'

const initialState = {
  isLoading: false,
  listTournament: {},
}

/**
 * tournament reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function tournamentManagement(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_TOURNAMENT_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_LIST_TOURNAMENT_SUCCESS:
      return {
        ...state,
        listTournament: action?.payload,
        isLoading: false,
      }

    case GET_LIST_TOURNAMENT_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
