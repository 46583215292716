import {
  GET_LIST_SECTORS_START,
  GET_LIST_SECTORS_SUCCESS,
  GET_LIST_SECTORS_FAILED,
  ADD_SECTOR_MEMBER_START,
  ADD_SECTOR_MEMBER_SUCCESS,
  ADD_SECTOR_MEMBER_FAILED,
  DELETE_SECTOR_MEMBER_START,
  DELETE_SECTOR_MEMBER_SUCCESS,
  DELETE_SECTOR_MEMBER_FAILED,
} from '../actions/sector'

const initialState = {
  isLoading: false,
  listSector: {},
  isLoadingAdd: false,
  isLoadingDelete: false,
}

/**
 * Account Profile reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function sectorManagement(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_SECTORS_START:
    case ADD_SECTOR_MEMBER_START:
    case DELETE_SECTOR_MEMBER_START:
      return {
        ...state,
        isLoading: true,
      }

    case GET_LIST_SECTORS_SUCCESS:
      return {
        ...state,
        listSector: action?.payload,
        isLoading: false,
      }

    case ADD_SECTOR_MEMBER_SUCCESS:
      return {
        ...state,
        isLoadingAdd: false,
      }

    case DELETE_SECTOR_MEMBER_SUCCESS:
      return {
        ...state,
        isLoadingDelete: false,
      }

    case GET_LIST_SECTORS_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    case ADD_SECTOR_MEMBER_FAILED:
      return {
        ...state,
        isLoadingAdd: false,
      }

    case DELETE_SECTOR_MEMBER_FAILED:
      return {
        ...state,
        isLoadingDelete: false,
      }
    default:
      return state
  }
}
