import { useTranslation } from '~/common/hooks/useTranslation'
import { Col, Modal, Row } from 'antd'
import React from 'react'
import { getNameItemV3 } from '~/helpers/ItemName'
import TimeHelper from '~/helpers/time-helper'
import { DATE_TIMEFORMAT_YYYY_MM_dd_HH_mm2 } from '~/common/constants'
import {
  formatAddress,
  formatAddressGiftDetail,
  formatNumber,
} from '~/helpers/common'
import configs from '~/configs'

function GiftDetail(props) {
  const { t } = useTranslation()
  const { isShowModal, onClose, data } = props

  const renderAddressesTxHash = (addresses) => {
    return (
      <>
        {addresses.map((address, index) => {
          const link = `${configs.NETWORK.scan}tx/${address}`
          return (
            <a
              key={index}
              target="_blank"
              rel="noopener noreferrer"
              href={link}
              title={address}
              style={{
                color: '#40A9FF',
                display: 'block',
                marginBottom: '5px',
              }}
            >
              {formatAddressGiftDetail(address)}
            </a>
          )
        })}
      </>
    )
  }
  const renderReceiverAddressesTo = (addresses) => {
    return (
      <>
        {addresses.map((address, index) => {
          let link = `${configs.NETWORK.scan}address/${address}`
          return (
            <a
              key={index}
              target="_blank"
              rel="noopener noreferrer"
              href={link}
              title={address}
              style={{
                color: '#40A9FF',
                display: 'block',
                marginBottom: '5px',
              }}
            >
              {formatAddressGiftDetail(address)}
            </a>
          )
        })}
      </>
    )
  }
  return (
    <Modal
      title={t('managementGift.giftDetail')}
      onCancel={onClose}
      open={isShowModal}
      width={1150}
      centered
      destroyOnClose
      footer={null}
    >
      <div className="ContainerGiftDetail">
        <Row
          style={{
            fontFamily: 'Rexliafree',
            padding: '16px',
          }}
        >
          <Col span={2} style={{ fontSize: '16px' }}>
            {t('managementGift.event')}
          </Col>
          <Col span={2} style={{ fontSize: '16px' }}>
            {t('managementGift.BIT')}
          </Col>
          <Col span={3} style={{ fontSize: '16px' }}>
            {t('managementGift.maPoint')}
          </Col>
          <Col span={5} style={{ fontSize: '16px' }}>
            {t('managementGift.assetType')}
          </Col>
          <Col span={3} style={{ fontSize: '16px' }}>
            {t('managementGift.amount')}
          </Col>
          <Col span={3} style={{ fontSize: '16px' }}>
            {t('managementGift.time')}
          </Col>
          <Col span={3} style={{ fontSize: '16px' }}>
            {t('managementGift.from')}
          </Col>
          <Col span={3} style={{ fontSize: '16px' }}>
            {t('managementGift.note')}
          </Col>
        </Row>
        <Row
          style={{
            background: '#0D2758',
            padding: '16px',
            borderRadius: '0 0 8px 8px',
          }}
        >
          <Col span={2}>Send gift</Col>
          <Col span={2}>{data?.bit ? formatNumber(data?.bit) : 'ー'}</Col>
          <Col span={3}>
            {data?.ma_point ? formatNumber(data?.ma_point) : 'ー'}
          </Col>
          <Col span={5}>
            {/* {data?.items?.map((parData, index) => (
              <React.Fragment key={index}>
                <div className="flex flex-col mb-1">
                  {parData?.typeItem?.map((subdata, subIndex) => (
                    <div className="flex items-center h-[25px]" key={subIndex}>
                      <div className="mr-2 object-contain">
                        <img
                          width={24}
                          height={24}
                          src={`${
                            process.env.REACT_APP_BASE_URL_IMAGE
                          }/public/image/image/items/${
                            getNameItemV3(subdata)?.image
                          }.png`}
                          alt={getNameItemV3(subdata)?.name || 'item image'}
                        />
                      </div>
                      <div>{getNameItemV3(subdata)?.name}</div>
                    </div>
                  ))}
                </div>
              </React.Fragment>
            ))} */}
            {data?.items?.length > 0 ? (
              data.items.map((parData, index) => (
                <React.Fragment key={index}>
                  <div className="flex flex-col mb-1">
                    {parData?.typeItem?.map((subdata, subIndex) => (
                      <div
                        className="flex items-center h-[25px]"
                        key={subIndex}
                      >
                        <div className="mr-2 object-contain">
                          <img
                            width={24}
                            height={24}
                            src={`${
                              process.env.REACT_APP_BASE_URL_IMAGE
                            }/public/image/image/items/${
                              getNameItemV3(subdata)?.image
                            }.png`}
                            alt=""
                          />
                        </div>
                        <div>{getNameItemV3(subdata)?.name}</div>
                        <img
                          width={20}
                          height={20}
                          src={`${process.env.PUBLIC_URL}/svgs/rankItem/${
                            getNameItemV3(subdata)?.quanlity
                          }.svg`}
                          alt=""
                          style={{ marginLeft: '8px' }}
                        />
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              ))
            ) : (
              <div>{'ー'}</div>
            )}
          </Col>
          <Col span={3}>
            {data?.items?.length > 0 ? (
              data.items.map((parData, index) => (
                <React.Fragment key={index}>
                  <div className="flex flex-col mb-1">
                    {parData?.amount?.length > 0 ? (
                      parData?.amount.map((subdata, subindex) => (
                        <div
                          key={subindex}
                          className="flex items-center h-[25px]"
                        >
                          <div className="mr-2 object-contain"></div>
                          <div>{formatNumber(subdata)}</div>
                        </div>
                      ))
                    ) : (
                      <div className="flex items-center h-[25px]">
                        <div className="mr-2 object-contain"></div>
                        <div></div>
                      </div>
                    )}
                  </div>
                </React.Fragment>
              ))
            ) : (
              <div>{'ー'}</div>
            )}
          </Col>
          <Col span={3}>
            {TimeHelper(data?.timestamp, {
              outputDefault: null,
              format: DATE_TIMEFORMAT_YYYY_MM_dd_HH_mm2,
            })}
          </Col>
          <Col span={3}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${configs.NETWORK.scan}address/${data?.from}`}
              title={data?.from}
              style={{ color: '#40A9FF' }}
            >
              {formatAddress(data?.from)}
            </a>
          </Col>
          <Col span={3}>{data?.note}</Col>
        </Row>
      </div>

      <div className="boxAddressGift">
        <div className="addressGiftDetail">
          <Row>
            <Col span={24} className="addressGiftDetailTitle">
              {t('managementGift.txHash')}
            </Col>
          </Row>
          <Row className="addressGiftDetailContent">
            <Col span={24} style={{ width: '100%' }}>
              {renderAddressesTxHash(data?.txid || [])}
            </Col>
          </Row>
        </div>
        <div className="addressGiftDetail">
          <Row>
            <Col span={24} className="addressGiftDetailTitle">
              {t('managementGift.to')}
            </Col>
          </Row>
          <Row className="addressGiftDetailContent">
            <Col span={24} style={{ width: '100%' }}>
              {renderReceiverAddressesTo(data?.receiver_address || [])}
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  )
}

export default GiftDetail
