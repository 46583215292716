import { call, put, takeLatest } from 'redux-saga/effects'
import {
  createBlackListsSuccess,
  createBlackListsFailed,
  CREATE_BLACKLIST_START,
} from '../../actions/blackList'
import { api } from '~/services/api'

/**
 * create blacklist api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const createBlackListsApi = (props) => {
  var uri = `/sponsor-tournament-blacklist`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doCreateBlackLists(action) {
  try {
    const response = yield call(createBlackListsApi, action?.payload)
    yield put(createBlackListsSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(createBlackListsFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch create blacklist
 */
export default function* watchCreateBlackLists() {
  yield takeLatest(CREATE_BLACKLIST_START, doCreateBlackLists)
}
