export const GET_LIST_TOURNAMENT_START = 'GET_LIST_TOURNAMENT_START'
export const GET_LIST_TOURNAMENT_SUCCESS = 'GET_LIST_TOURNAMENT_SUCCESS'
export const GET_LIST_TOURNAMENT_FAILED = 'GET_LIST_TOURNAMENT_FAILED'

//------------------------- Shop ------------------------//
/**
 * [1]
 * get list tournament start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getListTournament(payload, onSuccess, onError) {
  return {
    type: GET_LIST_TOURNAMENT_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get list tournament success action
 * @param {*} payload
 * @returns {object}
 */
export function getListTournamentSuccess(payload) {
  return {
    type: GET_LIST_TOURNAMENT_SUCCESS,
    payload: payload,
  }
}

/**
 * get list tournament failed action
 * @returns {object}
 */
export function getListTournamentFailed() {
  return {
    type: GET_LIST_TOURNAMENT_FAILED,
  }
}

export default {
  getListTournament,
  getListTournamentSuccess,
  getListTournamentFailed,
}
