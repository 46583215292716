import PropTypes from 'prop-types'
import { useTranslation } from '~/common/hooks/useTranslation'
import { Form, Modal, Input, Button } from 'antd'
import React, { useEffect } from 'react'
import { cancelButtonStyle, okButtonStyle } from '~/common/constants'
import { REGEX } from '~/helpers/regex'
function SectorForm(props) {
  const { t } = useTranslation()
  const { id, isShowModal, onClose, refreshData, dataSector } = props

  const [form] = Form.useForm()
  const rules = {
    sectorName: [
      () => ({
        validator(rule, value) {
          const regex = new RegExp(REGEX.PROFILE_NAME)
          if (value && value?.charAt(0) === '_') {
            return Promise.reject(
              'Name is a string of maximum 15 characters, which may include Latin letters, Japanese characters, numbers, spaces, or underscores, but must begin with a Latin letter, Japanese character or number.',
            )
          }
          if (value && value?.indexOf('-') !== -1) {
            return Promise.reject(
              'Name is a string of maximum 15 characters, which may include Latin letters, Japanese characters, numbers, spaces, or underscores, but must begin with a Latin letter, Japanese character or number.',
            )
          }
          if (value && !regex.test(value)) {
            return Promise.reject(
              'Name is a string of maximum 15 characters, which may include Latin letters, Japanese characters, numbers, spaces, or underscores, but must begin with a Latin letter, Japanese character or number.',
            )
          }
          return Promise.resolve()
        },
      }),

      {
        required: true,
        message: t('validate.required', {
          0: t('managementPools.rankingRewardsPool.form.poolName'),
        }),
      },
    ],
  }
  useEffect(() => {
    if (id && dataSector) {
      const formDetailData = {
        sectorName: dataSector.sectorName,
      }
      form.setFieldsValue(formDetailData)
    }
  }, [id, dataSector])

  const handleSubmit = (values) => {}

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  return (
    <Modal
      title={id ? t('sector.editSector') : t('sector.createSector')}
      onCancel={handleClose}
      open={isShowModal}
      width={600}
      centered
      destroyOnClose
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        form={form}
        translate="yes"
        autoComplete="off"
        size="middle"
        validateTrigger={['onBlur', 'onChange']}
      >
        <Form.Item
          label={t('sector.sectorName')}
          name="sectorName"
          rules={rules.sectorName}
          labelAlign="left"
        >
          <Input placeholder={t('sector.sectorName')} />
        </Form.Item>

        <div key="buttons" className="flex justify-center">
          <Button key="cancel" onClick={handleClose} style={cancelButtonStyle}>
            {t('common.btn.cancel')}
          </Button>
          <Button
            className="border-hidden"
            key="ok"
            htmlType="submit"
            type="primary"
            style={okButtonStyle}
            // disabled={isLoadingCreatePool}
            // loading={isLoadingCreatePool}
          >
            {id ? t('common.btn.update') : t('common.btn.create')}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

SectorForm.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default SectorForm
