import PropTypes from 'prop-types'
import { Form, Modal, Row, Col, Input, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'
import { useQueryState } from '~/common/hooks/useQueryState'
import { useUser } from '~/views/app/redux/hooks/useUser'
import {
  converRole,
  converUserRankUser,
  dataGuildLevel,
  getEndOfMonthUTC,
  getStartOfMonthUTC,
} from '~/common/constants'
import moment from 'moment'
import { formatNumber } from '~/helpers/common'
function UserGuild(props) {
  const { t } = useTranslation()
  const { isShowModal, onClose, data, currentCycle, listPool } = props

  const [formSearch] = Form.useForm()
  const [userWallet, setUserWallet] = useState([])
  const [sortListWallet, setSortListWallet] = useState([])

  const [onlineUsers, setOnlineUsers] = useState([])

  const { page, pageSize, keyword, setKeyword } = useQueryState()
  const {
    actions,
    data: { listUserByWallet },
  } = useUser()

  useEffect(() => {
    const userResult = data?.guild_member
      ? data.guild_member.map((item) => item.user_wallet)
      : []
    setUserWallet(userResult)
  }, [data])
  useEffect(() => {
    if (data && userWallet && userWallet.length > 0) {
      const params = {
        search: keyword,
        pageSize: pageSize,
        page,
        allWallets: userWallet,
      }
      const paramsScore = {
        search: keyword,
        pageSize: pageSize,
        page,
        user_wallets: userWallet,
      }
      actions.getUserByWallet(params)
      actions.getScoreByWallet(paramsScore)
    }
  }, [data, userWallet, pageSize, page, keyword])

  useEffect(() => {
    // Sort listUserByWallet based on userWallet order
    const sortedList = [...listUserByWallet].sort((a, b) => {
      const indexA = userWallet.indexOf(a.address_wallet)
      const indexB = userWallet.indexOf(b.address_wallet)
      return indexA - indexB
    })

    const mergedList = sortedList.map((user) => {
      const standing = data?.guild_member?.find(
        (item) => item.user_wallet === user.address_wallet,
      )
      return standing
        ? {
            ...user,
            member_role: standing.member_role || 0,
          }
        : user // If no matching standing is found, return the user as is
    })
    if (userWallet.length > 0) {
      setSortListWallet(
        mergedList.sort((a, b) => a.member_role - b.member_role),
      )
    } else {
      setSortListWallet([])
    }
  }, [listUserByWallet, userWallet, data, keyword])
  const handleClose = () => {
    onClose()
  }
  const handleSearch = (values) => {
    setKeyword(values?.keyword)
  }
  const getLastActive = (date, userId) => {
    const isOnline = moment(date).isAfter(moment().subtract(1, 'minute'))
    const lastLoginTime = moment(date).fromNow() // Time since last active
    if (isOnline && !onlineUsers.includes(userId)) {
      setOnlineUsers((prev) => [...prev, userId])
    }
    return isOnline
      ? 'Online'
      : moment(date).isAfter(moment().subtract(24, 'hours'))
      ? `Online \n ${lastLoginTime}`
      : `Online ${moment(date).calendar(null, {
          sameDay: '[Today]',
          lastDay: '[Yesterday]',
          lastWeek: '[Last] dddd',
          sameElse: 'DD/MM/YYYY',
        })}`
  }
  useEffect(() => {
    const updatedOnlineUsers = sortListWallet
      .filter((user) =>
        moment(user.last_active).isAfter(moment().subtract(1, 'minute')),
      )
      .map((user) => user.user_id)

    setOnlineUsers(updatedOnlineUsers)
  }, [sortListWallet])
  const filterDataGuildMember = data?.guild_member?.filter(
    (member, index, self) =>
      index === self.findIndex((m) => m.user_wallet === member.user_wallet),
  )
  const getUnixRange = (data) => {
    const times = data.flatMap(({ timeStart, timeEnd }) => [timeStart, timeEnd])
    const fromCycleTotal = Math.min(...times)
    const toCycleTotal = Math.max(...times)
    return { fromCycleTotal, toCycleTotal }
  }

  const { fromCycleTotal, toCycleTotal } = getUnixRange(listPool)

  const [scoreCycle, setScoreCycle] = useState([])
  const [scoreMonth, setScoreMonth] = useState([])
  const [scoreTotal, setScoreTotal] = useState([])

  useEffect(() => {
    const paramScoreSnapShotGuildMonth = {
      score_type: 'SECTOR',
      from_date: getStartOfMonthUTC(),
      to_date: getEndOfMonthUTC(),
    }
    actions.getScoreSnapShotAddress(paramScoreSnapShotGuildMonth, (res) => {
      if (res) {
        setScoreMonth(res?.data?.score_snapshot)
      }
    })
  }, [scoreCycle])
  useEffect(() => {
    const paramScoreSnapShotGuildCycle = {
      score_type: 'SECTOR',
      from_date: currentCycle?.timeStart,
      to_date: currentCycle?.timeEnd,
    }
    actions.getScoreSnapShotAddress(paramScoreSnapShotGuildCycle, (res) => {
      if (res) {
        setScoreCycle(res?.data?.score_snapshot)
      }
    })
  }, [scoreTotal, currentCycle])
  useEffect(() => {
    const paramScoreSnapShotGuildTotal = {
      score_type: 'SECTOR',
      from_date: fromCycleTotal,
      to_date: toCycleTotal,
    }
    actions.getScoreSnapShotAddress(paramScoreSnapShotGuildTotal, (res) => {
      if (res) {
        setScoreTotal(res?.data?.score_snapshot)
      }
    })
  }, [fromCycleTotal, toCycleTotal])

  const sumScoresByUser = (data) => {
    return data?.reduce((acc, { user, score }) => {
      const userAddress = user.toLowerCase()
      acc[userAddress] = (acc[userAddress] || 0) + Math.round(score)
      return acc
    }, {})
  }

  // Calculate scores for each data set
  const sumScoresByUserCycle = sumScoresByUser(scoreCycle)
  const sumScoresByUserMonth = sumScoresByUser(scoreMonth)
  const sumScoresByUserTotal = sumScoresByUser(scoreTotal)

  return (
    <Modal
      title={<>{t('managementGuilds.form.userGuild')}</>}
      onCancel={handleClose}
      open={isShowModal}
      width={1000}
      centered
      destroyOnClose
      footer={null}
    >
      <div className="flex sm:justify-end w-full">
        <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end">
          <Form
            onFinish={handleSearch}
            className="w-full sm:w-auto"
            form={formSearch}
            translate="yes"
            autoComplete="off"
            size="middle"
            validateTrigger={['onBlur', 'onChange']}
            requiredMark={false}
          >
            <Form.Item
              className="w-full"
              label={
                <span className="font__M__plus font-bold">
                  {t('common.search')}
                </span>
              }
              name="keyword"
              labelAlign="left"
            >
              <Input className="w-full" placeholder={t('common.search')} />
            </Form.Item>
          </Form>
        </div>
      </div>

      <div
        style={{
          background: '#1890ff',
          borderRadius: '8px',
          boxShadow: '0 0 6px 0 #2ca0ffcc',
          fontFamily: 'Rexliafree',
        }}
      >
        {/* Title */}
        <Row className="p-[12px]">
          <Col
            span={7}
            className="flex justify-center items-center  text-[16px] ml-[-30px]"
          >
            Member {filterDataGuildMember?.length}/
            {dataGuildLevel(data?.guild_level)?.maxMembers}
          </Col>
          <Col
            span={3}
            className="flex justify-center items-center text-[16px]  "
          >
            Rank
          </Col>
          <Col
            span={7}
            className="flex justify-center items-center text-[16px] "
          >
            Contribution
          </Col>
          <Col
            span={4}
            className="flex justify-center items-center text-[16px] "
          >
            Role
          </Col>
          <Col
            span={3}
            className="flex justify-center items-center  text-[16px]"
          >
            Online {onlineUsers?.length}/{filterDataGuildMember?.length}
          </Col>
        </Row>

        {/* Content */}
        {sortListWallet?.map((item, index) => (
          <div
            key={index}
            className="guildInfo"
            style={{
              background: '#0D2758',
              borderRadius:
                index === sortListWallet.length - 1 ? '0 0 8px 8px' : '0',

              maxHeight: '465px',
              overflowY: 'auto',
              borderBottom: '1px solid rgba(36, 169, 250, 0.3)',
            }}
          >
            <Row
              style={{
                borderRadius: '8px',
                position: 'relative',
                margin: '8px 0',
              }}
            >
              {item?.member_role === 0 && (
                <img
                  className="absolute left-[15px] bottom-[28px] w-[25px] h-[25px]"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/guildMaster.svg`}
                  alt=""
                />
              )}
              {item?.member_role === 1 && (
                <img
                  className="absolute left-[15px] bottom-[28px] w-[25px] h-[25px]"
                  src={`${process.env.PUBLIC_URL}/svgs/guild/subGuild.svg`}
                  alt=""
                />
              )}

              <Col
                span={7}
                className=" flex justify-center items-center text-[16px] ml-[-30px]"
              >
                <img
                  className=" w-[30px] h-[30px] mr-2"
                  // src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                  src={item?.avatar}
                  alt=""
                />
                <Tooltip title={item?.user_name}>
                  <span className="max-w-[80px] overflow-hidden whitespace-nowrap text-ellipsis">
                    {item?.user_name}
                  </span>
                </Tooltip>
              </Col>

              <Col
                span={3}
                className="flex justify-center items-center text-[16px] "
              >
                {converUserRankUser(item?.player_rank)}
              </Col>
              <Col
                span={7}
                className="flex justify-center items-center text-[16px] p-[5px] "
              >
                <Row>
                  <Col span={8} className="flex justify-center items-center ">
                    <img
                      className="w-[20px] h-[20px] mr-3 "
                      src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                      alt=""
                    />
                    <div>
                      <div>Total</div>
                      <div>Month</div>
                      <div>Cycle</div>
                    </div>
                  </Col>
                  <Col span={16} className="flex justify-end items-center ">
                    <div className="flex items-center flex-col ">
                      <div>
                        {formatNumber(
                          sumScoresByUserTotal[item.user_id.toLowerCase()] || 0,
                        )}
                      </div>
                      <div>
                        {formatNumber(
                          sumScoresByUserMonth[item.user_id.toLowerCase()] || 0,
                        )}
                      </div>
                      <div>
                        {formatNumber(
                          sumScoresByUserCycle[item.user_id.toLowerCase()] || 0,
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                span={4}
                className="flex justify-center items-center text-[16px] "
              >
                {converRole(item?.member_role)}
              </Col>
              <Col
                span={3}
                className={`flex justify-center items-center text-[16px] ${
                  moment(item?.last_active).isAfter(
                    moment().subtract(1, 'minute'),
                  )
                    ? 'text-[#2FFF39]'
                    : ''
                }`}
              >
                {getLastActive(item?.last_active, item?.user_id)}
              </Col>
            </Row>
          </div>
        ))}
      </div>
    </Modal>
  )
}

UserGuild.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default UserGuild
