import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getListPoolsSuccess,
  getListPoolsFailed,
  GET_LIST_POOL_START
} from '../../actions/pools'
import { api } from '~/services/api'

/**
 * get list pools api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getListPoolsApi = (props) => {
  var uri = `/admin/pools`
  return api.get(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetListPools(action) {
  try {
    const response = yield call(getListPoolsApi, action?.payload)
    yield put(getListPoolsSuccess(response))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(getListPoolsFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get list pools
 */
export default function* watchGetListPools() {
  yield takeLatest(GET_LIST_POOL_START, doGetListPools)
}
