export const GET_LIST_SECTORS_START = 'GET_LIST_SECTORS_START'
export const GET_LIST_SECTORS_SUCCESS = 'GET_LIST_SECTORS_SUCCESS'
export const GET_LIST_SECTORS_FAILED = 'GET_LIST_SECTORS_FAILED'

export const ADD_SECTOR_MEMBER_START = 'ADD_SECTOR_MEMBER_START'
export const ADD_SECTOR_MEMBER_SUCCESS = 'ADD_SECTOR_MEMBER_SUCCESS'
export const ADD_SECTOR_MEMBER_FAILED = 'ADD_SECTOR_MEMBER_FAILED'

export const DELETE_SECTOR_MEMBER_START = 'DELETE_SECTOR_MEMBER_START'
export const DELETE_SECTOR_MEMBER_SUCCESS = 'DELETE_SECTOR_MEMBER_SUCCESS'
export const DELETE_SECTOR_MEMBER_FAILED = 'DELETE_SECTOR_MEMBER_FAILED'
//------------------------- Sector ------------------------//
/**
 * [1]
 * get list sector start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getListSectors(payload, onSuccess, onError) {
  return {
    type: GET_LIST_SECTORS_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get list sector success action
 * @param {*} payload
 * @returns {object}
 */
export function getListSectorsSuccess(payload) {
  return {
    type: GET_LIST_SECTORS_SUCCESS,
    payload: payload,
  }
}

/**
 * get list sector failed action
 * @returns {object}
 */
export function getListSectorsFailed() {
  return {
    type: GET_LIST_SECTORS_FAILED,
  }
}

/**
 * [2]
 * add list member sector start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function addSectorMember(payload, onSuccess, onError) {
  return {
    type: ADD_SECTOR_MEMBER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * add list member sector success action
 * @param {*} payload
 * @returns {object}
 */
export function addSectorMemberSuccess(payload) {
  return {
    type: ADD_SECTOR_MEMBER_SUCCESS,
    payload: payload,
  }
}

/**
 * add list member sector failed action
 * @returns {object}
 */
export function addSectorMemberFailed() {
  return {
    type: ADD_SECTOR_MEMBER_FAILED,
  }
}

/**
 * [3]
 * delete list member sector start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function deleteSectorMember(payload, onSuccess, onError) {
  return {
    type: DELETE_SECTOR_MEMBER_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * delete list member sector success action
 * @param {*} payload
 * @returns {object}
 */
export function deleteSectorMemberSuccess(payload) {
  return {
    type: DELETE_SECTOR_MEMBER_SUCCESS,
    payload: payload,
  }
}

/**
 * delete list member sector failed action
 * @returns {object}
 */
export function deleteSectorMemberFailed() {
  return {
    type: DELETE_SECTOR_MEMBER_FAILED,
  }
}

export default {
  getListSectors,
  getListSectorsSuccess,
  getListSectorsFailed,

  addSectorMember,
  addSectorMemberSuccess,
  addSectorMemberFailed,

  deleteSectorMember,
  deleteSectorMemberSuccess,
  deleteSectorMemberFailed,
}
