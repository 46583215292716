import { useTranslation } from '~/common/hooks/useTranslation'
import { Button, Col, Row, Space } from 'antd'
import React, { useState } from 'react'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import { EyeOutlined } from '@ant-design/icons'
import SectorForm from './form'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import SectorDetail from './modalDetail'
import { fakeDataSector } from './fakedata'
import moment from 'moment'
import { DATE_TIMEFORMAT_YYYY_MM_dd_HH_mm2 } from '~/common/constants'

const Sector = () => {
  const { t } = useTranslation()
  // const [formSearch] = Form.useForm()
  const {
    data: { user, total },
  } = useTheme()

  const { page, pageSize, keyword, setPage, setPageSize, setKeyword } =
    useQueryState()

  //Control all modal
  const [modalForm, setModalForm] = useState({
    id: null,
    isOpenModal: false,
    sector: {},
  })
  const [modalDetail, setModalDeail] = useState({
    id: null,
    isOpenModal: false,
    sector: {},
  })

  const columns = [
    {
      title: t('sector.sectorName'),
      dataIndex: 'sectorName',
      key: 'sectorName',
      render: (sectorName) => {
        return <div className="">{sectorName}</div>
      },
    },
    {
      title: t('sector.totalUser'),
      dataIndex: 'totalUser',
      key: 'totalUser',
      render: (totalUser) => {
        return <div className="">{totalUser}</div>
      },
    },
    {
      title: t('sector.createAt'),
      dataIndex: 'createAt',
      key: 'createAt',

      render: (createAt) => {
        return (
          <div className="">
            {moment(createAt * 1000)
              .utc()
              .format(DATE_TIMEFORMAT_YYYY_MM_dd_HH_mm2)}
          </div>
        )
      },
    },

    {
      title: t('common.action'),
      render: (_, data) => {
        return (
          <Space>
            <Button
              type="ghost"
              loading={false}
              onClick={() => onShowModalDetail(data?._id, data)}
              icon={<EyeOutlined style={{ color: '#1890FF' }} />}
            />
          </Space>
        )
      },
    },
  ]

  const rowClassName = (record) => {
    return record?.address_wallet === user?.address_wallet
      ? 'highlight-row'
      : ''
  }

  const onShowModalDetail = (id, sector) => {
    setModalDeail({
      id,
      isOpenModal: true,
      sector: sector,
    })
  }

  const onCloseModalForm = () => {
    setModalForm({
      id: null,
      isOpenModal: false,
      sector: {},
    })
  }
  const onCloseModalDetail = () => {
    setModalDeail({
      id: null,
      isOpenModal: false,
      sector: {},
    })
  }

  return (
    <div>
      <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
        <Col xs={24} sm={12}></Col>
        <Col xs={24} sm={12}>
          <div className="flex sm:justify-end w-full">
            <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end"></div>
          </div>
        </Col>
      </Row>
      <Datatable
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={fakeDataSector?.data}
        rowClassName={rowClassName}
        // loading={isLoadingListPool}
        // showPagination
        // showHeader
        metadata={{
          perPage: pageSize,
          setPerPage: setPageSize,
          page,
          setPage,
          total: total,
          keyword,
          setKeyword,
        }}
      />

      {modalForm.isOpenModal && (
        <SectorForm
          isShowModal={modalForm.isOpenModal}
          onClose={onCloseModalForm}
          // refreshData={refreshData}
          id={modalForm.id}
          dataSector={modalForm?.sector}
        />
      )}
      {modalDetail.isOpenModal && (
        <SectorDetail
          isShowModal={modalDetail.isOpenModal}
          onClose={onCloseModalDetail}
          // refreshData={refreshData}
          id={modalDetail.id}
          dataSector={modalDetail?.sector}
        />
      )}
    </div>
  )
}

export default Sector
