import { call, put, takeLatest } from 'redux-saga/effects'
import {
  getListGuildsSuccess,
  getListGuildsFailed,
  GET_LIST_GUILD_START,
} from '../../actions/guild'
import { api } from '~/services/api/gameApi'
// import { api } from '~/services/api/blockchainApi'

/**
 * get list guild pools api
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
const getListGuildsApi = (props) => {
  var uri = `/api/game/guild/get-by-name`
  return api.post(uri, props)
}

/**
 * [Worker]
 * Handle get data request and response
 * @param {object} action
 */
function* doGetListGuilds(action) {
  try {
    const response = yield call(getListGuildsApi, action?.payload)
    yield put(getListGuildsSuccess(response?.data))
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess(response)
    }
  } catch (error) {
    yield put(getListGuildsFailed())
    // Call callback action if provided

    if (action.onError) {
      yield action.onError(error)
    }
  }
}

/**
 * [Watcher]
 * Watch get list guild pools
 */
export default function* watchGetListGuilds() {
  yield takeLatest(GET_LIST_GUILD_START, doGetListGuilds)
}
