import { put, takeLatest } from 'redux-saga/effects'

import {
  logoutGameFailed,
  logoutGameSuccess,
  LOGOUT_GAME_START,
} from '../../actions/auth'
import {
  AUTH_TOKEN_GAME,
  REFRESH_TOKEN_GAME,
  USER_INFO_GAME,
} from '~/common/constants/ApiConstant'

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doLogoutGame(action) {
  try {
    // Remove wallet from local storage
    localStorage.removeItem('walletGame')

    // Remove token from local storage
    localStorage.removeItem(AUTH_TOKEN_GAME)

    // Remove refresh token from local storage
    localStorage.removeItem(REFRESH_TOKEN_GAME)

    // Remove user infomation from local storage
    localStorage.removeItem(USER_INFO_GAME)

    localStorage.removeItem('tokenGame')

    yield put(logoutGameSuccess())
    // Call callback action if provided
    if (action.onSuccess) {
      yield action.onSuccess()
    }

    // const isTrigger = action?.callbackUrl

    // if (!isTrigger) {
    //   window.location.reload()
    // }
  } catch (error) {
    yield put(logoutGameFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError()
    }
  }
}

/**
 * Watch search users
 */
export default function* watchLogoutGame() {
  yield takeLatest(LOGOUT_GAME_START, doLogoutGame)
}
