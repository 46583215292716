export const DATE_FORMAT_YYYYMMdd = 'YYYYMMdd'
export const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD'
export const DATE_FORMAT_YYYY_MM_dd = 'YYYY-MM-dd'
export const DATE_FORMAT_YYYY_MM_dd_Combined = 'YYYYMMddHHmm'
export const DATE_FORMAT_YYYY_MM_dd_HH_mm = 'YYYY-MM-dd HH:mm'
export const DATE_TIMEFORMAT_YYYY_MM_dd_HH_mm = 'YYYY-MM-dd HH:mm:ss'
export const DATE_TIMEFORMAT_YYYY_MM_dd_HH_mm2 = 'YYYY/MM/DD HH:mm:ss'
export const DATE_FORMAT_DD_MM_YYYY = 'DD-MM-YYYY'
export const DATE_FORMAT_DD_MM_YYYY_WITH_DOT = 'DD.MM.YYYY'
export const DATE_FORMAT_mm_dd_YYYY_WITH_SLASH = 'MM/dd/YYYY'
export const DATE_FORMAT_m_d_YYYY_WITH_SLASH = 'M/d/YYYY'
export const DATE_FORMAT_DD_MM_YYYY_WITH_SLASH = 'DD/MM/YYYY'
export const DATE_FORMAT_dd_MMM = 'ddMMM'
export const DATE_FORMAT_dd_MM_yy = 'ddMMyy'
export const DATE_FORMAT_dd_MMM_YYYY = 'ddMMMYYYY'
export const DATE_FORMAT_DD_MM_YYYY_COMBINED = 'DDMMYYYY'
export const DATE_FORMAT_DD_MM_YYYY_HH_mm_ss = "YYYY-MM-dd'T'HH:mm:ss"
export const DATE_FORMAT_DD_MM_YYYY_HH_mm_ss_sz = "YYYY-MM-dd'T'HH:mm:ss.SSS"
export const DATE_FORMAT_DD_MM_YYYY_HH_mm = 'dd-MM-YYYY HH:mm'
export const DATE_FORMAT_HH_mm = 'HH:mm'
export const DATE_FORMAT_YYYY_MM_DDTHH_mm_ssZ = "YYYY-MM-DD'T'HH:mm:ssZ"
export const DATE_FORMAT_USAGE_TIME = 'HH:mm'
export const DATE_FORMAT_TIME = 'HH:mm:ss'
export const DATE_FORMAT_TEST_TIME = "mm's"
export const YEAR_FORMAT = 'YYYY'
export const formatTimeMinuteSecond = (seconds) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
}
export const getWeekTimestamps = () => {
  const currentDate = new Date()
  const startOfWeek = new Date(
    Date.UTC(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate() -
        (currentDate.getUTCDay() === 0 ? 6 : currentDate.getUTCDay() - 1),
    ),
  )
  startOfWeek.setUTCHours(0, 0, 0, 0)
  const fromDateWeek = Math.floor(startOfWeek.getTime() / 1000)
  const endOfWeek = new Date(startOfWeek)
  endOfWeek.setUTCDate(startOfWeek.getUTCDate() + 6)
  endOfWeek.setUTCHours(23, 59, 59, 999)
  const toDateWeek = Math.floor(endOfWeek.getTime() / 1000)

  return { fromDateWeek, toDateWeek }
}
export const getStartOfMonthUTC = () => {
  const now = new Date()
  return Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), 1, 0, 0, 0) / 1000
}

export const getEndOfMonthUTC = () => {
  const now = new Date()
  return (
    Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 1, 0, 23, 59, 59) / 1000
  )
}
