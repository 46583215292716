export const GET_LIST_BLACKLIST_START = 'GET_LIST_BLACKLIST_START'
export const GET_LIST_BLACKLIST_SUCCESS = 'GET_LIST_BLACKLIST_SUCCESS'
export const GET_LIST_BLACKLIST_FAILED = 'GET_LIST_BLACKLIST_FAILED'

export const GET_LIST_ADD_BLACKLIST_START = 'GET_LIST_ADD_BLACKLIST_START'
export const GET_LIST_ADD_BLACKLIST_SUCCESS = 'GET_LIST_ADD_BLACKLIST_SUCCESS'
export const GET_LIST_ADD_BLACKLIST_FAILED = 'GET_LIST_ADD_BLACKLIST_FAILED'

export const CREATE_BLACKLIST_START = 'CREATE_BLACKLIST_START'
export const CREATE_BLACKLIST_SUCCESS = 'CREATE_BLACKLIST_SUCCESS'
export const CREATE_BLACKLIST_FAILED = 'CREATE_BLACKLIST_FAILED'

export const DELETE_BLACKLIST_START = 'DELETE_BLACKLIST_START'
export const DELETE_BLACKLIST_SUCCESS = 'DELETE_BLACKLIST_SUCCESS'
export const DELETE_BLACKLIST_FAILED = 'DELETE_BLACKLIST_FAILED'

//-------------------------BlackList------------------------//
/**
 * [1]
 * get list blacklist start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getListBlackLists(payload, onSuccess, onError) {
  return {
    type: GET_LIST_BLACKLIST_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get list blacklist success action
 * @param {*} payload
 * @returns {object}
 */
export function getListBlackListsSuccess(payload) {
  return {
    type: GET_LIST_BLACKLIST_SUCCESS,
    payload: payload,
  }
}

/**
 * get list blacklist failed action
 * @returns {object}
 */
export function getListBlackListsFailed() {
  return {
    type: GET_LIST_BLACKLIST_FAILED,
  }
}

/**
 * [2]
 * get list add blacklist start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getListAddBlackLists(payload, onSuccess, onError) {
  return {
    type: GET_LIST_ADD_BLACKLIST_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get list add blacklist success action
 * @param {*} payload
 * @returns {object}
 */
export function getListAddBlackListsSuccess(payload) {
  return {
    type: GET_LIST_ADD_BLACKLIST_SUCCESS,
    payload: payload,
  }
}

/**
 * get list add blacklist failed action
 * @returns {object}
 */
export function getListAddBlackListsFailed() {
  return {
    type: GET_LIST_ADD_BLACKLIST_FAILED,
  }
}

/**
 * [3]
 * create blacklist start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function createBlackLists(payload, onSuccess, onError) {
  return {
    type: CREATE_BLACKLIST_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * create blacklist success action
 * @param {*} payload
 * @returns {object}
 */
export function createBlackListsSuccess(payload) {
  return {
    type: CREATE_BLACKLIST_SUCCESS,
    payload: payload,
  }
}

/**
 * create blacklist failed action
 * @returns {object}
 */
export function createBlackListsFailed() {
  return {
    type: CREATE_BLACKLIST_FAILED,
  }
}

/**
 * [4]
 * delete blacklist start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function deleteBlackLists(payload, onSuccess, onError) {
  return {
    type: DELETE_BLACKLIST_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * delete blacklist success action
 * @param {*} payload
 * @returns {object}
 */
export function deleteBlackListsSuccess(payload) {
  return {
    type: DELETE_BLACKLIST_SUCCESS,
    payload: payload,
  }
}

/**
 * delete blacklist failed action
 * @returns {object}
 */
export function deleteBlackListsFailed() {
  return {
    type: DELETE_BLACKLIST_FAILED,
  }
}

export default {
  getListBlackLists,
  getListBlackListsSuccess,
  getListBlackListsFailed,

  getListAddBlackLists,
  getListAddBlackListsSuccess,
  getListAddBlackListsFailed,

  createBlackLists,
  createBlackListsSuccess,
  createBlackListsFailed,

  deleteBlackLists,
  deleteBlackListsSuccess,
  deleteBlackListsFailed,
}
