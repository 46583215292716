import { Button, DatePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'
import Datatable from '~/components/shared-components/Datatable'
import TimeHelper from '~/helpers/time-helper'
import { useQueryState } from '~/common/hooks/useQueryState'
import configs from '~/configs'
import { formatAddress, formatNumber } from '~/helpers/common'
import ManagementGiftForm from './form'
import { useGift } from '../../redux/hooks/useGift'
import { getNameItemV3 } from '~/helpers/ItemName'
import { DATE_TIMEFORMAT_YYYY_MM_dd_HH_mm2 } from '~/common/constants'
import GiftDetail from './form/modalGiftDetail'
const { RangePicker } = DatePicker

function ManagementGift() {
  const { t } = useTranslation()
  const { page, pageSize, keyword, setPage, setPageSize, setKeyword } =
    useQueryState()
  const [date, setDate] = useState(['', ''])
  const [maxToLength, setMaxToLength] = useState(null)
  const {
    actions,
    data: { listGifts, isLoading, total },
  } = useGift()

  //Control all modal
  const [modalForm, setModalForm] = useState({
    id: null,
    isOpenModal: false,
  })

  const [modalDetail, setModalDetail] = useState({
    id: null,
    isOpenModal: false,
  })

  const onShowModalForm = (id) => {
    setModalForm({
      id,
      isOpenModal: true,
    })
  }
  const onShowModalDetail = (data) => {
    setModalDetail({
      data,
      isOpenModal: true,
    })
  }

  // Handle close modal (create-edit-delete)
  const onCloseModalForm = () => {
    setModalForm({
      id: null,
      isOpenModal: false,
    })
  }
  const onCloseModalDetail = () => {
    setModalDetail({
      data: null,
      isOpenModal: false,
    })
  }

  const renderReceiverAddressesTo = (addresses) => {
    const maxDisplay = maxToLength
    return (
      <>
        {addresses.slice(0, maxDisplay).map((address, index) => {
          let link = `${configs.NETWORK.scan}address/${address}`
          return (
            <a
              key={index}
              target="_blank"
              rel="noopener noreferrer"
              href={link}
              title={address}
              style={{
                color: '#40A9FF',
                display: 'block',
                marginBottom: '5px',
              }}
            >
              {formatAddress(address)}
            </a>
          )
        })}
        {addresses.length > maxDisplay && (
          <span style={{ display: 'block', color: '#40A9FF' }}>...</span>
        )}
      </>
    )
  }
  const renderAddressesTxHash = (addresses) => {
    const maxDisplay = maxToLength
    return (
      <>
        {addresses.slice(0, maxDisplay).map((address, index) => {
          const link = `${configs.NETWORK.scan}tx/${address}`
          return (
            <a
              key={index}
              target="_blank"
              rel="noopener noreferrer"
              href={link}
              title={address}
              style={{
                color: '#40A9FF',
                display: 'block',
                marginBottom: '5px',
              }}
            >
              {formatAddress(address)}
            </a>
          )
        })}
        {addresses.length > maxDisplay && (
          <span style={{ display: 'block', color: '#40A9FF' }}>...</span>
        )}
      </>
    )
  }
  const columns = [
    {
      title: t('managementGift.event'),
      width: 100,
      render: () => {
        return 'Send gift'
      },
    },
    {
      title: t('managementGift.BIT'),
      dataIndex: 'bit',
      key: 'bit',
      render: (_, data) => {
        return <div>{data.bit ? formatNumber(data.bit) : 'ー'}</div>
      },
    },
    {
      title: t('managementGift.maPoint'),
      dataIndex: 'ma_point',
      key: 'ma_point',
      render: (_, data) => {
        return <div>{data.ma_point ? formatNumber(data.ma_point) : 'ー'}</div>
      },
    },
    {
      title: t('managementGift.assetType'),
      key: 'assetType',
      render: (_, data) => {
        setMaxToLength(data?.items?.length)
        return (
          <div>
            {data?.items?.length > 0 ? (
              data.items.map((parData, index) => (
                <React.Fragment key={index}>
                  <div className="flex flex-col mb-1">
                    {parData?.typeItem?.map((subdata, subIndex) => (
                      <div
                        className="flex items-center h-[25px]"
                        key={subIndex}
                      >
                        <div className="mr-2 object-contain">
                          <img
                            width={24}
                            height={24}
                            src={`${
                              process.env.REACT_APP_BASE_URL_IMAGE
                            }/public/image/image/items/${
                              getNameItemV3(subdata)?.image
                            }.png`}
                            alt=""
                          />
                        </div>
                        <div>{getNameItemV3(subdata)?.name}</div>
                        <img
                          width={20}
                          height={20}
                          src={`${process.env.PUBLIC_URL}/svgs/rankItem/${
                            getNameItemV3(subdata)?.quanlity
                          }.svg`}
                          alt=""
                          style={{ marginLeft: '8px' }}
                        />
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              ))
            ) : (
              <div>{'ー'}</div>
            )}
          </div>
        )
      },
    },
    {
      title: t('managementGift.amount'),
      key: 'amount',
      render: (_, data) => {
        return (
          <div>
            {data?.items?.length > 0 ? (
              data.items.map((parData, index) => (
                <React.Fragment key={index}>
                  <div className="flex flex-col mb-1">
                    {parData?.amount?.length > 0 ? (
                      parData?.amount.map((subdata, subindex) => (
                        <div
                          key={subindex}
                          className="flex items-center h-[25px]"
                        >
                          <div className="mr-2 object-contain"></div>
                          <div>{formatNumber(subdata)}</div>
                        </div>
                      ))
                    ) : (
                      <div className="flex items-center h-[25px]">
                        <div className="mr-2 object-contain"></div>
                        <div></div>
                      </div>
                    )}
                  </div>
                  {/* {index < data?.items?.length - 1 && <hr />} */}
                </React.Fragment>
              ))
            ) : (
              <div>{'ー'}</div>
            )}
          </div>
        )
      },
    },
    {
      title: t('managementGift.time'),
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp) => {
        return TimeHelper(timestamp, {
          outputDefault: null,
          format: DATE_TIMEFORMAT_YYYY_MM_dd_HH_mm2,
        })
      },
    },
    {
      title: t('managementGift.from'),
      dataIndex: 'from',
      key: 'from',
      render: (from) => {
        let link = `${configs.NETWORK.scan}address/${from}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            title={from}
            style={{ color: '#40A9FF' }}
          >
            {formatAddress(from)}
          </a>
        )
      },
    },
    {
      title: t('managementGift.to'),
      dataIndex: 'receiver_address',
      key: 'receiver_address',
      render: (receiver_address) => {
        return renderReceiverAddressesTo(receiver_address)
      },
    },
    {
      title: t('managementGift.note'),
      dataIndex: 'note',
      key: 'note',
      render: (note) => {
        return note
      },
    },
    {
      title: t('managementGift.txHash'),
      dataIndex: 'txid',
      key: 'txid',
      render: (txid) => {
        return renderAddressesTxHash(txid)
      },
    },
    {
      title: t('managementGift.action'),
      key: 'eye',
      render: (_, data) => {
        return (
          <div
            className="cursor-pointer"
            onClick={() => onShowModalDetail(data)}
          >
            <img
              // className="w-[16px] h-[16px] mr-1"
              src={`${process.env.PUBLIC_URL}/svgs/eye.svg`}
              alt=""
            />
          </div>
        )
      },
    },
  ]

  //Refresh data
  const refreshData = () => {
    const params = {
      search: keyword,
      pageSize: pageSize,
      page,
    }
    if (date[0] !== '') {
      let temp = date[0].split('-')
      let temp1 = date[1].split('-')
      params.fromTime = Date.UTC(temp[0], temp[1] - 1, temp[2], 0, 0, 0)
      params.toTime = Date.UTC(temp1[0], temp1[1] - 1, temp1[2], 23, 59, 59)
    }
    actions.getListGifts(params)
  }

  useEffect(() => {
    refreshData()
  }, [date, page, pageSize, keyword])

  const onChangeDate = (values) => {
    setDate(values)
  }

  return (
    <div>
      <div className="flex justify-between gap-3 mb-5 box__filter">
        <Button
          type="primary"
          onClick={() => {
            onShowModalForm(null)
          }}
        >
          {t('common.btn.sendGift')}
        </Button>

        <div className="flex items-center">
          <div className="mr-2 font__Rexliafree">
            {t('managementGift.date')}
          </div>
          <RangePicker
            onChange={(values, dateString) => onChangeDate(dateString)}
            className="h-full max-w-[256px]"
          />
        </div>
      </div>
      <Datatable
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={listGifts}
        showPagination
        showHeader
        loading={isLoading}
        metadata={{
          perPage: pageSize,
          setPerPage: setPageSize,
          page,
          setPage,
          total,
          keyword,
          setKeyword,
        }}
      />

      <ManagementGiftForm
        isShowModal={modalForm.isOpenModal}
        onClose={onCloseModalForm}
        refreshData={refreshData}
        id={modalForm?.id}
      />
      <GiftDetail
        isShowModal={modalDetail.isOpenModal}
        onClose={onCloseModalDetail}
        refreshData={refreshData}
        data={modalDetail?.data}
      />
    </div>
  )
}

export default ManagementGift
