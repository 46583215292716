import {
  BankOutlined,
  BarsOutlined,
  DashboardOutlined,
  GiftOutlined,
  HistoryOutlined,
  HomeOutlined,
  MoneyCollectOutlined,
  SettingOutlined,
  ShopOutlined,
  TeamOutlined,
  TrophyOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { ROUTE } from '~/views/app/routes/config'

const dashBoardNavTree = [
  {
    key: ROUTE.HOME.PATH,
    path: ROUTE.HOME.PATH,
    title: ROUTE.HOME.TITLE,
    icon: DashboardOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: ROUTE.MARKETPLACE_DASHBOARD.PATH,
        path: ROUTE.MARKETPLACE_DASHBOARD.PATH,
        title: ROUTE.MARKETPLACE_DASHBOARD.TITLE,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: ROUTE.SHOP_DASHBOARD.PATH,
        path: ROUTE.SHOP_DASHBOARD.PATH,
        title: ROUTE.SHOP_DASHBOARD.TITLE,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: ROUTE.GAME_DASHBOARD.PATH,
        path: ROUTE.GAME_DASHBOARD.PATH,
        title: ROUTE.GAME_DASHBOARD.TITLE,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
  {
    key: ROUTE.HISTORY_TRADE.PATH,
    path: ROUTE.HISTORY_TRADE.PATH,
    title: ROUTE.HISTORY_TRADE.TITLE,
    icon: HistoryOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: ROUTE.MANAGEMENT_COLLABORATION_NFT.PATH,
    path: ROUTE.MANAGEMENT_COLLABORATION_NFT.PATH,
    title: ROUTE.MANAGEMENT_COLLABORATION_NFT.TITLE,
    icon: BarsOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: ROUTE.MARKETPLACE_SETTINGS.TITLE,
    path: ROUTE.MARKETPLACE_SETTINGS.PATH,
    title: ROUTE.MARKETPLACE_SETTINGS.TITLE,
    icon: MoneyCollectOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: ROUTE.SHOP.TITLE,
    path: ROUTE.SHOP.PATH,
    title: ROUTE.SHOP.TITLE,
    icon: ShopOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: ROUTE.MANAGEMENT_GIFT.TITLE,
    path: ROUTE.MANAGEMENT_GIFT.PATH,
    title: ROUTE.MANAGEMENT_GIFT.TITLE,
    icon: GiftOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: ROUTE.MANAGEMENT_POOLS.PATH,
    path: ROUTE.MANAGEMENT_POOLS.PATH,
    title: ROUTE.MANAGEMENT_POOLS.TITLE,
    icon: BankOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: ROUTE.HASHCHIP_REDEEMED.PATH,
        path: ROUTE.HASHCHIP_REDEEMED.PATH,
        title: ROUTE.HASHCHIP_REDEEMED.TITLE,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: ROUTE.RANKING_REWARDS.PATH,
        path: ROUTE.RANKING_REWARDS.PATH,
        title: ROUTE.RANKING_REWARDS.TITLE,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: ROUTE.RANKING_POOL.PATH,
        path: ROUTE.RANKING_POOL.PATH,
        title: ROUTE.RANKING_POOL.TITLE,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
  {
    key: ROUTE.MANAGEMENT_USERS.TITLE,
    path: ROUTE.MANAGEMENT_USERS.PATH,
    title: ROUTE.MANAGEMENT_USERS.TITLE,
    icon: UserOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: ROUTE.ADMINS.PATH,
        path: ROUTE.ADMINS.PATH,
        title: ROUTE.ADMINS.TITLE,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: ROUTE.USERS.PATH,
        path: ROUTE.USERS.PATH,
        title: ROUTE.USERS.TITLE,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
  {
    key: ROUTE.PROFILE_SETTINGS.TITLE,
    path: ROUTE.PROFILE_SETTINGS.PATH,
    title: ROUTE.PROFILE_SETTINGS.TITLE,
    icon: SettingOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: ROUTE.GUILD.TITLE,
    path: ROUTE.GUILD.PATH,
    title: ROUTE.GUILD.TITLE,
    icon: HomeOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: ROUTE.MANAGEMENT_SPONSOR_TOURNAMENT.TITLE,
    path: ROUTE.MANAGEMENT_SPONSOR_TOURNAMENT.PATH,
    title: ROUTE.MANAGEMENT_SPONSOR_TOURNAMENT.TITLE,
    icon: TrophyOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: ROUTE.SPONSOR_TOURNAMENT.PATH,
        path: ROUTE.SPONSOR_TOURNAMENT.PATH,
        title: ROUTE.SPONSOR_TOURNAMENT.TITLE,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: ROUTE.SPONSOR_WHITELIST.PATH,
        path: ROUTE.SPONSOR_WHITELIST.PATH,
        title: ROUTE.SPONSOR_WHITELIST.TITLE,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: ROUTE.SPONSOR_BLACKLIST.PATH,
        path: ROUTE.SPONSOR_BLACKLIST.PATH,
        title: ROUTE.SPONSOR_BLACKLIST.TITLE,
        breadcrumb: true,
        submenu: [],
      },
      // {
      //   key: ROUTE.SHOP_DASHBOARD.PATH,
      //   path: ROUTE.SHOP_DASHBOARD.PATH,
      //   title: ROUTE.SHOP_DASHBOARD.TITLE,
      //   breadcrumb: true,
      //   submenu: [],
      // },
    ],
  },
  // {
  //   key: ROUTE.SECTOR.TITLE,
  //   path: ROUTE.SECTOR.PATH,
  //   title: ROUTE.SECTOR.TITLE,
  //   icon: TeamOutlined,
  //   breadcrumb: true,
  //   submenu: [],
  // },
]

const navigationConfig = [...dashBoardNavTree]

export default navigationConfig
