import React from 'react'
import { Pie } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement, // Import ArcElement
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { useTranslation } from '~/common/hooks/useTranslation'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement, // Register ArcElement
  Title,
  Tooltip,
  Legend,
)

const RankingChart = (props) => {
  const { dataRanking } = props

  // eslint-disable-next-line
  const { t } = useTranslation()

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: true,
        labels: {
          color: '#fff',
          usePointStyle: true,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  }

  const data = {
    labels: [
      t(`managementPools.rankingRewardsPool.form.tournament`),
      t(`managementPools.rankingRewardsPool.form.weekly`),
      t(`managementPools.rankingRewardsPool.form.pvp`),
      t(`managementPools.rankingRewardsPool.form.exploraion`),
      t(`managementPools.rankingRewardsPool.form.guild`),
      t(`managementPools.rankingRewardsPool.form.sector`),
    ],
    datasets: [
      {
        label: 'Ranking',
        data: [
          dataRanking?.tournament?.percentage,
          dataRanking?.weekly?.percentage,
          dataRanking?.personalPvP?.percentage,
          dataRanking?.personalExploraion?.percentage,
          dataRanking?.guild?.percentage,
          dataRanking?.sector?.percentage,
        ],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
      },
    ],
  }

  return (
    <div style={{ width: 300, height: 300 }}>
      <Pie options={options} data={data} />
    </div>
  )
}

export default RankingChart
