import { useTranslation } from '~/common/hooks/useTranslation'
import { Button, Col, Image, Row, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import { EyeOutlined } from '@ant-design/icons'
import SponsorTournamentDetail from './modalDetailSponsor'
import { useTournament } from '../../redux/hooks/useTournament'
import { StateSponsorTournament } from '~/common/constants'

const SponsorTournament = () => {
  const { t } = useTranslation()
  // const [formSearch] = Form.useForm()
  const { page, pageSize, keyword, setPage, setPageSize, setKeyword } =
    useQueryState()
  const {
    actions,
    data: { listTournament, isLoading },
  } = useTournament()

  const refreshData = () => {
    actions.getListTournament()
  }
  useEffect(() => {
    refreshData()
  }, [page, pageSize, keyword])

  const [modalDetail, setModalDetail] = useState({
    id: null,
    isOpenModal: false,
    sponsor: {},
  })

  const getColor = (color) => {
    switch (color) {
      case 4:
        return {
          border: '1px solid rgba(102, 187, 106, 1)',
          background: 'rgba(102, 187, 106, 1)',
        }
      case 5:
        return {
          border: '1px solid rgba(255, 0, 0, 1)',
          background: 'rgba(255, 0, 0, 1)',
        }
      case 2:
        return {
          border: '1px solid rgba(237, 135, 0, 1)',
          background: 'rgba(237, 135, 0, 1)',
        }
      case 3:
        return {
          border: '1px solid rgba(237, 135, 0, 1)',
          background: 'rgba(237, 135, 0, 1)',
        }
      default:
        return {
          border: '1px solid rgba(12, 200, 254, 1)',
          background: 'rgba(24, 45, 86, 1)',
        }
    }
  }

  const columns = [
    {
      title: t('sponsorsTournament.tournamentImage'),
      dataIndex: 'banner',
      key: 'banner',
      width: 200,
      render: (banner) =>
        banner ? (
          <Image
            className="imageTournament"
            style={{ width: '30vh', height: '100px', objectFit: 'cover' }}
            src={banner}
          />
        ) : (
          'ー'
        ),
    },
    {
      title: (
        <div className="text-[16px] flex justify-center">
          {t('sponsorsTournament.tournamentName')}
        </div>
      ),
      dataIndex: 'tournament_name',
      key: 'tournament_name',
      render: (name) => (
        <div
          className="text-[16px] flex justify-center"
          style={{ fontFamily: 'Rexliafree' }}
        >
          {name}
        </div>
      ),
    },
    {
      title: (
        <div className="text-[16px] flex justify-center">
          {t('sponsorsTournament.status')}
        </div>
      ),
      dataIndex: 'state',
      key: 'state',
      render: (state) => (
        <div
          className="text-[16px] flex justify-center"
          style={{
            ...getColor(state),
            padding: '10px',
            borderRadius: '16px',
            fontFamily: 'Rexliafree',
          }}
        >
          {StateSponsorTournament(state)}
        </div>
      ),
    },
    {
      title: (
        <div className="text-[16px] flex justify-center">
          {t('sponsorsTournament.time')}
        </div>
      ),
      dataIndex: 'date',
      key: 'date',
      render: (time) => {
        const date = new Date(time * 1000)
        const formattedDate = new Intl.DateTimeFormat('en-US', {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          timeZone: 'GMT',
        }).format(date)
        const formattedTime = date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
          timeZone: 'GMT',
        })
        return (
          <div
            className="text-[16px] flex justify-center"
            style={{ fontFamily: 'Rexliafree' }}
          >{`${formattedDate} at ${formattedTime}`}</div>
        )
      },
    },
    {
      render: (_, data) => (
        <Space>
          <Button
            type="ghost"
            onClick={() => onShowModalDetail(data?._id, data)}
            icon={<EyeOutlined style={{ color: '#1890FF' }} />}
          />
        </Space>
      ),
    },
  ]

  const onShowModalDetail = (id, sponsor) => {
    setModalDetail({ id, isOpenModal: true, sponsor })
  }

  const onCloseModalDetail = () => {
    setModalDetail({ id: null, isOpenModal: false, sponsor: {} })
  }

  // const handleSearch = (values) => setKeyword(values?.keyword)

  // Apply pagination by slicing the list based on page and pageSize
  const paginatedData = listTournament?.sponsor_tournament_list
    ?.filter((item) => !item.is_draft)
    ?.slice((page - 1) * pageSize, page * pageSize)

  return (
    <div>
      <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
        <Col xs={24} sm={12}></Col>
        <Col xs={24} sm={12}>
          <div className="flex sm:justify-end w-full">
            <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end">
              {/* <Form
                onFinish={handleSearch}
                className="w-full sm:w-auto"
                form={formSearch}
                translate="yes"
                autoComplete="off"
                size="middle"
                validateTrigger={['onBlur', 'onChange']}
                requiredMark={false}
              >
                <Form.Item
                  className="w-full"
                  label={
                    <span className="font__M__plus font-bold">
                      {t('common.search')}
                    </span>
                  }
                  name="keyword"
                  labelAlign="left"
                >
                  <Input className="w-full" placeholder={t('common.search')} />
                </Form.Item>
              </Form> */}
            </div>
          </div>
        </Col>
      </Row>
      <Datatable
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={paginatedData}
        loading={isLoading}
        showPagination
        metadata={{
          perPage: pageSize,
          setPerPage: setPageSize,
          page,
          setPage,
          total: listTournament?.sponsor_tournament_list?.filter(
            (item) => !item.is_draft,
          ).length,
          keyword,
          setKeyword,
        }}
      />
      {modalDetail.isOpenModal && (
        <SponsorTournamentDetail
          isShowModal={modalDetail.isOpenModal}
          onClose={onCloseModalDetail}
          refreshData={refreshData}
          id={modalDetail.id}
          sponsor={modalDetail?.sponsor}
        />
      )}
    </div>
  )
}

export default SponsorTournament
